import dotEnv from "dotenv";
import { createOfferContractTypes } from "../commondata/commonData";

dotEnv.config()
const { detectWebView, getValuesFromCookies } = require("../utils/universalFuntions");
const apiURL = process.env.REACT_APP_WEB_BASE_URL;
const adminApiURL = process.env.REACT_APP_ADMIN_BASE_URL;


// when the user clicks on the close icon
export const handleClose = async ({ source, builtForSection, urlId, tabId, openFor, contractType, documentId, ertsId, offerId, queryOrigin, clientId, propertyId, rootDocData, signatureInfo, history, readOnly }) => {
    if (source === "ContactSheet" && readOnly) {
        history.push(`/success?client=true`);
        return;
    }
    const redirectHome = async () => {

        const mobileDataToSend = {
            contractType,
            builtForSection,
        };
        if (contractType === "ERTS" || createOfferContractTypes.includes(contractType)) {
            if (openFor === "SELLER_AGENT") {
                mobileDataToSend.category = "SELLER";
                mobileDataToSend.categoryId = contractType === "ERTS" ? documentId : propertyId;
            } else {
                mobileDataToSend.category = "BUYER";
                mobileDataToSend.categoryId = offerId;
                if (createOfferContractTypes.includes(rootDocData?.contractType)) {
                    mobileDataToSend.signed = signatureInfo?.length ? true : false;
                }
            }
            await window?.ReactNativeWebView?.postMessage(JSON.stringify(mobileDataToSend));
        } else {
            await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
        }
    };
    const isInWebView = detectWebView();
    if (queryOrigin === "true") {
        if (window.history.state !== null) {
            window.history?.back();
        } else {
            window.location = `${apiURL}`;
        }
        localStorage.removeItem(urlId);
        return;
    }
    if (builtForSection === "DOCUMENT") {
        if (openFor === "BUYER" || openFor === "SELLER" || openFor === "BUYER_WITHDRAW") {
            if (["ERTB", "LCAE", "BDB", "BDS", "DWR", "ERTL", "ETCT", "LCAES_ERTL", "LCAES_ETCT"].includes(contractType)) {
                history.push(`/success?client=true`);
            } else {
                window.location = `${apiURL}/clientScreen`;
            }
            localStorage.removeItem(urlId);
            return;
        }
    }
    if (isInWebView && source !== 'ShowEmailContent') {
        redirectHome();
    } else {
        if (source !== "ShowEmailContent" && tabId?.includes("_page2")) tabId = tabId.replace("_page2", ""); // For TR report case
        const origin = await getValuesFromCookies(tabId);
        // console.log("origin check", origin, apiURL);
        // return;
        switch (true) {
            case origin === "/transactions":
            case origin === "/account/delegates/transactions":
                if (openFor === "SELLER_AGENT") {
                    if (contractType === "ERTS") {
                        window.location.href = documentId ? `${apiURL}${origin}?category=SELLER&categoryId=${documentId}` : `${apiURL}${origin}`;
                    } else {
                        window.location.href = propertyId || ertsId ? `${apiURL}${origin}?category=SELLER&categoryId=${propertyId ? propertyId : ertsId}` : `${apiURL}${origin}`;
                    }
                } else {
                    window.location.href = offerId ? `${apiURL}${origin}?category=BUYER&categoryId=${offerId}` : `${apiURL}${origin}`;
                }
                break;
            case origin?.includes("/bDashboard"):
            case origin?.includes("/account/delegates/transactions/bDashboard"):
                if (openFor === "BUYER_AGENT" && createOfferContractTypes.includes(contractType)) {
                    if (!signatureInfo?.length) {
                        if (origin?.includes("/account/delegates/transactions/bDashboard")) {
                            window.location.href = `${apiURL}/account/delegates/transactions?category=BUYER&categoryId=${offerId}`;
                        } else {
                            window.location.href = `${apiURL}/transactions?category=BUYER&categoryId=${offerId}`;
                        }
                    } else {
                        window.location.href = `${apiURL}${origin}`;
                    }
                } else {
                    window.location.href = `${apiURL}${origin}`;
                }
                break;
            case origin?.startsWith("/offer/"):
                window.location.href = `${adminApiURL}${origin}`;
                break;
            case origin === "/contacts":
                window.location.href = clientId ? `${apiURL}${origin}?reqId=${clientId}&key=Docs` : `${apiURL}${origin}`;
                break;
            case origin?.includes("contractDocument"):
                if (source === "ShowEmailContent" && !isInWebView) {
                    window.location.href = `${adminApiURL}${origin}?tabId=${tabId}`;
                } else if (source === "ShowEmailContent" && isInWebView) {
                    try {
                        window.history?.back();
                    } catch (e) {
                        window.location.href = apiURL;
                    }
                }
                break;
            default:
                if (origin && origin !== "undefined" && origin !== "null") {
                    window.location.href = `${apiURL}${origin}`;
                } else {
                    try {
                        window.history?.back();
                    } catch (e) {
                        window.location.href = apiURL;
                    }
                }
        }
    }
    localStorage.removeItem(urlId);
};