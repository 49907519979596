/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { SideBar } from "./SideBar";
import { Navbar } from "./Navbar";
import { Layout, Spin } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountAction } from "../../Accounts/state/actions";

const { Content } = Layout;

/**
 * @function PrivateRoute
 * @param {Object} Object parent object paraments
 * @returns {Object} JSX.Element
 * Higher order function that validates the auth token before proceeding
 */

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();
  let accessToken = localStorage.getItem("auth");
  const { adminName, getUserDetailsLoading } = useSelector((state) => state.account);
  const {isAdminRoleUpdated} = useSelector((state) => state.officeType)
  const [selectedMenu, setselectedMenu] = useState({
    showVisible:false,
    showVisible2:false,
    showVisible3:false,
    showVisible4:false,
    showaccordian:false,
    showaccordian2:false,
    showaccordian3:false,
    showAccordion4:false,
  });


  useEffect(() => {
    dispatch(accountAction.getUserDetails({}));
  }, [isAdminRoleUpdated]);

  let toggle = () => {
    setCollapsed(!collapsed);
  };


  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken ? (
          <>
            {getUserDetailsLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <div>
                <Layout style={{ height: "100vh",overflowY:"hidden" }}>
                  <SideBar
                      toggle={toggle}
                      collapsed={collapsed}
                      selectedMenu={selectedMenu}
                      setselectedMenu={setselectedMenu}
                      />
                  <Layout style={{ height: "100vh" }}>
                    <Navbar
                     getUserName={adminName}
                     toggle={toggle}
                     collapsed={collapsed}
                     setselectedMenu={setselectedMenu}
                     selectedMenu={selectedMenu}
                    />
                    <Content
                      style={{
                       margin: "24px 16px 0",
                        background: "rgb(202, 202, 202)",
                      }}>
                      <Component {...props} />
                    </Content>
                    {/* <Footer style={{ textAlign: "center" }}>
                      Design ©2023 - Hana Software, Inc.
                    </Footer> */}
                  </Layout>
                </Layout>
              </div>
            )}
          </>
        ) : (
          <Redirect to="/admin" />
        )
      }
    />
  );
};
