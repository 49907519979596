const { formatPhoneNumber } = require("../../../Common/utils/universalFuntions");

const buildSignatureBlocksForAgents = ({ signers, type, rootDocData, contractData, requiredOpenFor }) => {
  if (!Array.isArray(signers)) {
    console.log("Unable to build signature block for", signers);
    return "";
  }
  let brokerFields = {};
  if (type === "BUYER_BROKER" || type === "CO_BUYER_BROKER") {
    brokerFields = contractData?.buyerBrokerFields || {};
  } else if (type === "SELLER_BROKER" || type === "CO_SELLER_BROKER") {
    brokerFields = contractData?.sellerBrokerFields || {};
  }

  let html = "";
  signers.forEach((element) => {
    const personId = element.personId || element._id;
    const uuidKey = element.key || element.personId;
    const isActiveSigner = rootDocData?.openFor === requiredOpenFor && !rootDocData?.checkForDisable?.disabled && ((personId === rootDocData?.builtForId && personId === rootDocData?.key) || requiredOpenFor === "TITLE_COMPANY") && rootDocData?.signable;

    const signerStatus = isActiveSigner ? "active-signer clickable" : "inactive-signer";
    const signerId = `${requiredOpenFor}_${personId || requiredOpenFor}_key_${uuidKey}`;
    const signatureDateId = `${requiredOpenFor}_${personId || element._id || requiredOpenFor}_key_${uuidKey}`;
    const editIconDataAttr = type === "CO_BUYER_BROKER" ? "data-co-buyer-broker-edit-icon" : type === "CO_SELLER_BROKER" ? "data-co-seller-broker-edit-icon" : "";
    const deleteIconDataAttr = type === "CO_BUYER_BROKER" ? "data-co-buyer-broker-delete-icon" : type === "CO_SELLER_BROKER" ? "data-co-seller-broker-delete-icon" : "";

    html += `
      <div class="signature-block">
        <table class="mt">
          <thead></thead>
          <tbody>
            <tr>
              <td style="width: 80%;" class="border-bottom-input" id="${signerId}">
                <div class="signature-block ${signerStatus}" data-signerId="${personId}" data-signerKey="${uuidKey}"></div>
              </td>
              <td style="width: 25%; vertical-align: bottom;" class="border-bottom-input ua tb" data-signatureDate="${signatureDateId}"></td>
            </tr>
            <tr>
             <td>${brokerFields.signatureLabel ? brokerFields.signatureLabel.trim() : "Broker's Name:"} <span class="ua" data-signerFullName="${signerId}">${element.fullName || ""}</span>
                <i class="bi bi-pencil-square no-print clickable" data-block-is-for="${type}"  ${editIconDataAttr} data-signer-personId="${personId}" data-signer-uuid-key="${uuidKey}"style="color: blue"></i>
                <i class="bi bi-trash3 no-print clickable" data-block-is-for="${type}" ${deleteIconDataAttr} data-signer-personId="${personId}" data-signer-uuid-key="${uuidKey}"style="color: #178DFA"></i>
              </td>
              <td>Date</td>
            </tr>
            <table class="tableWidth" style="border: 0;">
              ${brokerFields.firmName ? `<tr><td>Brokerage Firm’s Name: <span class="ua">${element.firmName || ""}</span></td></tr>` : ""}
              ${brokerFields.firmLicense ? `<tr><td>Brokerage Firm’s License #: <span class="ua">${element.firmLicense || ""}</span></td></tr>` : ""}
              ${brokerFields.license ? `<tr><td>Broker's License #: <span class="ua">${element.license || ""}</span></td></tr>` : ""}
              ${brokerFields.address ? `<tr><td style="width: 30%;">Address: <span class="ua">${element.address || ""}</span></td></tr>` : ""}
              ${brokerFields.phoneNumber ? `<tr><td>Phone No.: <span class="ua">${formatPhoneNumber(element.phoneNumber)}</span></td></tr>` : ""}
              ${brokerFields.faxNumber ? `<tr><td>Fax No.: <span class="ua">${formatPhoneNumber(element.faxNumber)}</span></td></tr>` : ""}
              ${brokerFields.email ? `<tr><td>Email Address: <span class="ua">${element.email || ""}</span></td></tr>` : ""}
            </table>
          </tbody>
        </table>
      </div>`;
  });

  return html;
};

export default buildSignatureBlocksForAgents;
