import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Menu, Dropdown, Layout, Row, Col, Typography, Badge } from "antd";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { Redirect } from "react-router-dom";
import { adminAction } from "../../Authenticate/state/actions";
import styles from "../styles/PrivateRoute.module.css";
import { useEffect } from "react";
import { Help } from "./Help";
const { Header } = Layout;
const { Text } = Typography;

/**
 * @function Navbar
 * @returns {Object} JSX.element
 * Navbar component which is rendered for all protected routes or redirects if user is not logged in
 */
function Navbar({ getUserName, toggle, collapsed, selectedMenu, setselectedMenu }) {
  const {  getUserDetailsData } = useSelector((state) => state.account);
  const [section, setSection] = useState('');
  const [adminRoles, setAdminRoles] = useState([]);

  const dispatch = useDispatch();
  const logout = () => {
    //Removing redirect key from local Storage If some one log out. Saved when some one saved a document or templated
    localStorage.removeItem("redirectTo");
    dispatch(adminAction.logOut());
  };

  const { logOutRedirect } = useSelector((state) => state.authenticate);

 const handlecall=()=>{
    setselectedMenu({
        showVisible:!selectedMenu.showVisible,
        showVisible2:!selectedMenu.showVisible2,
        showVisible3:!selectedMenu.showVisible3,
    });
    toggle();
 }
  const menu = (
    <Menu>
      <Menu.Item key="0" disabled>
        Hi {getUserName}
      </Menu.Item>
      <Menu.Item key="1" onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  );


  function getCurrentPathname() {
    return window.location.pathname;
  }
  let paramName = getCurrentPathname();

  useEffect(()=>{
    let adminRolesArray = [];
    getUserDetailsData?.adminRole?.forEach((element) => {
      if(element === "SUPER_ADMIN"){
        adminRolesArray.push("Super Admin")
      }
      else if(element === "RS_SUPPORT"){
        adminRolesArray.push("RE-Sure Support")
      }
      else if(element === "ADMIN_FRANCHISE"){
        adminRolesArray.push("Admin Franchise")
      }
      // else if(element === "ADMIN_OFFICE"){
      //   adminRolesArray.push("Admin office")
      // }
      else if(element === "ADMIN_BROKERAGE"){
        adminRolesArray.push("Admin Brokerage")
      }
      else if(element === "ADMIN_TITLE_COMPANY"){
        adminRolesArray.push("Admin Title Company")
      }
      else if(element === "ADMIN_TITLE_OFFICE"){
        adminRolesArray.push("Admin Title Office")
      }
    });
    setAdminRoles(adminRolesArray);
  },[getUserDetailsData])

  useEffect(() => {
       if(paramName === '/'){
          setSection('ADMIN_DASHBOARD')
       }else if (paramName==='/firmVerification' || paramName==='/firmCreation' || paramName==='/firmaccess'){
         setSection('ADMIN_FIRM')
       }else if (paramName==='/titleCompanyCreation' || paramName==='/titleCompanyVerification'){
          setSection('TITLE_COMPANY')
       }else if(paramName==='/accounts'){
          setSection('MANAGE_ACCOUNTS')
       }else if(paramName ==='/subscriptionVerification'){
           setSection('MANAGE_SUBSCRIPTION')
       }else if(paramName ==='/listing'){
          setSection('LISTING_DOCUMENTS')
       }
       else if(paramName ==='/listEdit'){
          setSection('CATEGORY')
       }
       else if(paramName ==='/upload'){
          setSection('UPLOAD_DOC')
       }
       else if(paramName ==='/user'){
          setSection('USERS')
       }
       else if(paramName ==='/nda'){
          setSection('NDA')
       }
       else if(paramName ==='/bugs'){
          setSection('BUGS')
       }
       else if(paramName ==='/help'){
          setSection('MANAGE_HELP_ADMIN')
       }
}, [paramName])

  if (logOutRedirect) return <Redirect to={"/logout"} />;

  return (
    <>
      <Header
        className="site-layout-background"
        style={{ padding: 0, background: "#fff" }}
      >
        <Row>
          <Col
            flex="1 1 1px"
            style={{
              fontSize: "25px",
              lineHeight: "64px",
              cursor: "pointer",
              transition: "all .5s ease-in-out",
              color: "rgb(78, 115, 223)",
            }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: `${styles.trigger}`,
                onClick: handlecall,
              }
            )}
          </Col>
          <Col>
          <div>
          <div><Help section={section} /></div>
          </div>
          </Col>
          <Col flex="0 1 auto" style={{marginRight:"10px"}}>
          <div style={{display:"flex"}}>
            <Text
              key="7"
              style={{

                // color: "#858796 !important",
                marginRight: "10px",
              }}
            ><div style={{width:"100%",height:"20px",paddingBottom:"3px",textAlign:"center"}}>
             <div style={{height:"100%",marginBottom:"3px"}}> {getUserName}</div>

             <div style={{height:"100%"}}>



      <Badge
        className="site-badge-count-109"
         count= {adminRoles?.join(", ")}
        style={{ backgroundColor: "rgb(195, 188, 188)",marginBottom:"7px" ,color:"yellow",background:"#1890ff",minWidth:"120px"}}
        />
        </div>
              </div>
            </Text>
            <Dropdown key="4" overlay={menu} trigger={["click", "hover"]}>
              <Avatar key="5" size="large" icon={<UserOutlined />} style={{marginTop:"15px",marginRight:"5px"}}/>
            </Dropdown>
           </div>
          </Col>
        </Row>
      </Header>
    </>
  );
}
export { Navbar };
