// import days from "dayjs";
var dayjs = require("dayjs");
let CryptoJS = require("crypto-js");
const { message } = require("antd");

const googleFonts = ["Great Vibes", "Cedarville Cursive", "Calligraffitti", "Allura", "Pacifico", "Dancing Script", "Whisper"];

let optionsForSort = [
  "PRICE",
  "CLOSING_DATE",
  "DOWN_PAYMENT_AMOUNT",
  "LOAN_TYPE",
];

const listingOrClientContractTypes = ["ERTS", "ERTB", "DWR", "BDB", "BDS", "LCAE", "ERTL", "ETCT", "LCAES_ERTL", "LCAES_ETCT"];
const createOfferContractTypes = ["CBSR", "CBS_MH", "CBS_LAND", "CBS_CL", "CBS_IR", "CBS_COFPA"];
const noChangeCheckboxContractTypes = ["ATAE", "ATAES", "CO", "MH_ATAE", "MH_ATAES", "MH_CO"];
const amendExtendContractTypes = ["ATAE", "ATAES", "MH_ATAE", "MH_ATAES"];
const conterOfferContractTypes = ["CO", "MH_CO"];
let loanTypes = ["CONVENTIONAL", "FHA", "VA", "BOND", "OTHER LOAN LIMITATIONS"];
let optionsForOrder = ["ASC", "DEC"];


var daysData = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// let dateChangeRequest = (request) => {
//   var options = { year: "numeric", month: "numeric", day: "numeric" };
//   let d = new Date(request);
//   let updateDate = new Date(d.setDate(d.getDate() - 1)).toLocaleDateString(
//     "en-US",
//     options
//   );
//   let dd = new Date(updateDate);
//   if (
//     daysData[dd.getDay()] === "Sunday" ||
//     daysData[dd.getDay()] === "Saturday"
//   ) {
//     updateDate = new Date(d.setDate(d.getDate() - 1)).toLocaleDateString(
//       "en-US",
//       options
//     );
//   }

// };

let getDateData = (dateChange) => {
  var options = { year: "numeric", month: "numeric", day: "numeric" };
  let d = new Date(dateChange);
  // dateChangeRequest(dateChange);
  let updateDate = new Date(d.setDate(d.getDate() - 1)).toLocaleDateString(
    "en-US",
    options
  );
  const date = new Date(updateDate);
  // let exceptDay = daysData[date.getDate()];

  // if (exceptDay === "Sunday" || exceptDay === "Saturday") {
  //   getDateData(updateDate);
  // } else {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  day < 10 && (day = `0${day}`);
  month < 10 && (month = `0${month}`);

  return `${year}-${month}-${day}`;
  // }
};


let rowDatakeys = [
  "Purchase Price",
  "Earnest Money",
  "New Loan",
  "Assumption Balance",
  "Private Financing",
  "Seller Financing",
  "Cash at Closing",
  "Price",
  "Total Amount",
];

//calculates the purchase price at the time of onCHange Event
let purchaseNames = (text) => {
  let rowData = {
    "Purchase Price": true,
    "Earnest Money": true,
    "New Loan": true,
    "Assumption Balance": true,
    "Private Financing": true,
    "Seller Financing": true,
    "Cash at Closing": true,
    Price: true,
    "Total Amount": true,
  };

  return rowData[text] || false;
};

let doubleDigitNumber = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
];


let convertDate = (date, source,key) => {
 
  if (date && date !=="undefined") {
    if(key==="Date Submitted"){
    return dayjs(date).format("MM/DD/YYYY hh:mm:ss A");
    }
    const parsedDate = dayjs(date);
    if (parsedDate.isValid()) {
      return parsedDate.format("MM/DD/YYYY");
    } else {
      return date;
    }
  } else {
    return source= "OFFER_TABLE"?"N/A":"";
  }
};
 // convert the time format into the 12h format
 function convertTime(time) {
  if (time && time !== "undefined") {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { 
      time = time.slice(1); 
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjusting hours
    }
    return time.join(''); 
  } else {
    return "";
  }
}



let getParsePrice = (number) => {
  if (number) {
    let convert = parseFloat(number).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
    return convert;
  }
  return number;
};

const formatPrice = (price, source) => {
  if (!price) {
    return source ? "0.00" : "";
  }
  const cleanedPrice = price.toString()?.replace(/[^0-9.-]/g, ''); //allowing the minus sign also
  // const cleanedPrice = price.toString()?.replace(/[^0-9.]/g, '')
  const numericPrice = parseFloat(cleanedPrice);
  if (isNaN(numericPrice) || !numericPrice) {
    return source ? "0.00" : "";
  }
  const priceString = numericPrice.toString();
  const hasDecimal = priceString.includes('.');
  // Adding a minus sign for negative values
  const formattedPrice = (numericPrice < 0 ? '-' : '') +
    (hasDecimal
      ? Math.abs(numericPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : Math.abs(numericPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.00');
  return formattedPrice;
}

let loanTypeData = ["Conventional", "FHA", "VA", "Bond"];


let decrypt = (response) => {
  let bytes = CryptoJS.AES.decrypt(response, "url resure encryption");

  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// let decryptQuery = (response) => {
//   let bytes = CryptoJS.AES.decrypt(JSON.parse(response), "url resure encryption");
//   return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// };
let decryptQuery = (response) => {
  try {
    if (!response || response.trim() === '') {
      return null
    }
    let jsonData = JSON.parse(response);
    let bytes = CryptoJS.AES.decrypt(jsonData, "url resure encryption");
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Error decrypting query:', error.message);
    return null; 
  }
};


let decryptStrQuery = (response) => {
  try {
    if (!response || response.trim() === '') {
      return null
    }
    let jsonData = JSON.parse(JSON.stringify(response));
    let bytes = CryptoJS.AES.decrypt(jsonData, "url resure encryption");
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Error decrypting query:', error.message);
    return null; 
  }
};

let encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, 'url resure encryption').toString();
}

const Table31Inputs = [
  "TimeOfDayDeadline",
  "AlternativeEarnestMoneyDeadline",
  "RecordTitleDeadline",
  "RecordTitleObjectionDeadline",
  "OffRecordTitleDeadline",
  "OffRecordTitleObjectionDeadline",
  "TitleResolutionDeadline",
  "ThirdPartyRightToPurchaseApproveDeadline",
  "AssociationDocumentsDeadline",
  "AssociationDocumentsTerminationDeadline",
  "SellersPropertyDisclosureDeadline",
  "LeadBasedPaintDisclosureDeadline",
  "NewLoanApplicationDeadline",
  "NewLoanTermsDeadline",
  "NewLoanAvailabilityDeadline",
  "BuyersCreditInformationDeadline",
  "DisapprovalOfBuyersCreditInformationDeadline",
  "ExistingLoanDeadline",
  "ExistingLoanTerminationDeadline",
  "LoanTransferApprovalDeadline",
  "SellerOrPrivateFinancingDeadline",
  "AppraisalDeadline",
  "AppraisalObjectionDeadline",
  "AppraisalResolutionDeadline",
  "NewILCOrNewSurveyDeadline",
  "NewILCOrNewSurveyObjectionDeadline",
  "NewILCOrNewSurveyResolutionDeadline",
  "WaterRightsExaminationDeadline",
  "MineralRightsExaminationDeadline",
  "InspectionTerminationDeadline",
  "InspectionObjectionDeadline",
  "InspectionResolutionDeadline",
  "PropertyInsuranceTerminationDeadline",
  "DueDiligenceDocumentsDeliveryDeadline",
  "DueDiligenceDocumentsObjectionDeadline",
  "DueDiligenceDocumentsResolutionDeadline",
  "ConditionalSaleDeadline",
  "LeadBasedPaintTerminationDeadline",
  "ClosingDate",
  "PossessionDate",
  "PossessionTime",
  "AcceptanceDeadlineDate",
  "AcceptanceDeadlineTime"
];

let dateData = [
  {
    type: "time",
    keyName: "TimeOfDayDeadline",
    canRemoved:true,
    time: "21:00",
  },
  {
    type: "date",
    keyName: "AlternativeEarnestMoneyDeadline",
    canRemoved:true,
    differnce: "1-3",
  },
  {
    type: "date",
    keyName: "RecordTitleDeadline",
    canRemoved:true,
    differnce: "7",
  },
  {
    type: "date",
    keyName: "RecordTitleObjectionDeadline",
    canRemoved:true,
    differnce: "10",
  },
  {
    type: "date",
    keyName: "OffRecordTitleDeadline",
    canRemoved:true,
    differnce: "7",
  },
  {
    type: "date",
    keyName: "OffRecordTitleObjectionDeadline",
    canRemoved:true,
    differnce: "10",
  },
  {
    type: "date",
    keyName: "TitleResolutionDeadline",
    canRemoved:true,
    differnce: "13-15",
  },
  // {
  //   type: "date",
  //   keyName: "ThirdPartyRightToPurchaseApproveDeadline",
  //   initialVal:"N/A"
  // },
  {
    type: "date",
    keyName: "AssociationDocumentsDeadline",
    canRemoved:true,
    differnce: "7-10",
  },
  {
    type: "date",
    keyName: "AssociationDocumentsTerminationDeadline",
    canRemoved:true,
    differnce: "10-13",
  },
  {
    type: "date",
    keyName: "SellersPropertyDisclosureDeadline",
    canRemoved:true,
    differnce: "1-3",
  },
  {
    type: "date",
    keyName: "LeadBasedPaintDisclosureDeadline",
    canRemoved:true,
    differnce: "1-3",
  },
  {
    type: "date",
    keyName: "NewLoanApplicationDeadline",
    canRemoved:true,
    differnce: "1-3",
  },
  {
    type: "date",
    keyName: "NewLoanTermsDeadline",
    canRemoved:true,
    differnce: "5-7",
  },
  {
    type: "date",
    keyName: "NewLoanAvailabilityDeadline",
    canRemoved:true,
    differnce: "25-30",
  },
  // {
  //   type: "date",
  //   keyName: "BuyersCreditInformationDeadline",
  //   // differnce: "5-7",
  //   initialVal:"N/A"
  // },
  // {
  //   type: "date",
  //   keyName:"DisapprovalOfBuyersCreditInformationDeadline",
  //   initialVal: "N/A",
  //   // differnce: "10-13",
  // },
  // {
  //   type: "date",
  //   keyName: "ExistingLoanDeadline",
  //   initialVal:"N/A"
  //   // differnce: "7-10",
  // },
  // {
  //   type: "date",
  //   keyName: "ExistingLoanTerminationDeadline",
  //   initialVal:"N/A"
  //   // differnce: "11-13",
  // },
  // {
  //   type: "date",
  //   keyName: "LoanTransferApprovalDeadline",
  //   initialVal:"N/A"
  //   // differnce: "20-25",
  // },
  // {
  //   type: "date",
  //   keyName: "SellerOrPrivateFinancingDeadline",
  //   initialVal:"N/A"
  //   // differnce: "30-40",
  // },
  {
    type: "date",
    keyName: "AppraisalDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  {
    type: "date",
    keyName: "AppraisalObjectionDeadline",
    canRemoved:true,
    differnce: "16-18",
  },
  {
    type: "date",
    keyName: "AppraisalResolutionDeadline",
    canRemoved:true,
    differnce: "20-23",
  },
  {
    type: "date",
    keyName: "NewILCOrNewSurveyDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  {
    type: "date",
    keyName: "NewILCOrNewSurveyObjectionDeadline",
    canRemoved:true,
    differnce: "18-21",
  },
  {
    type: "date",
    keyName: "NewILCOrNewSurveyResolutionDeadline",
    canRemoved:true,
    differnce: "21-24",
  },
  {
    type: "date",
    keyName: "WaterRightsExaminationDeadline",
    canRemoved:true,
    differnce: "10-13",
  },
  {
    type: "date",
    keyName: "MineralRightsExaminationDeadline",
    canRemoved:true,
    differnce: "10-13",
  },
  {
    type: "date",
    keyName: "InspectionTerminationDeadline",
    canRemoved:true,
    differnce: "7-10",
  },
  {
    type: "date",
    keyName: "InspectionObjectionDeadline",
    canRemoved:true,
    differnce: "11-14",
  },
  {
    type: "date",
    keyName: "InspectionResolutionDeadline",
    canRemoved:true,
    differnce: "15-18",
  },
  {
    type: "date",
    keyName: "PropertyInsuranceTerminationDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  {
    type: "date",
    keyName: "DueDiligenceDocumentsDeliveryDeadline",
    canRemoved:true,
    differnce: "5-7",
  },
  {
    type: "date",
    keyName: "DueDiligenceDocumentsObjectionDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  {
    type: "date",
    keyName: "DueDiligenceDocumentsResolutionDeadline",
    canRemoved:true,
    differnce: "18-21",
  },
  {
    type: "date",
    keyName: "ConditionalSaleDeadline",
    canRemoved:true,
    differnce: "28",
  },
  {
    type: "date",
    keyName: "LeadBasedPaintTerminationDeadline",
    canRemoved:true,
    differnce: "14-18",
  },
  // {
  //   type: "date",
  //   keyName: "ClosingDate",
  //   canRemoved:false,
  //   differnce: "30-40",
  // },
  {
    type: "date",
    keyName: "PossessionDate",
    canRemoved:true,
    differnce: "30-40",
  },
  {
    type: "time",
    keyName: "PossessionTime",
    canRemoved:true,
    time: "21:00",
  },
  {type:"date", keyName:"AcceptanceDeadlineDate", canRemoved:true, differnce: "1"},
  {type:"time",keyName:"AcceptanceDeadlineTime", canRemoved:true,  time: "21:00"},
];



let isPublicHoliday = [
  "2021-01-01",
  "2022-01-17",
  "2022-02-21",
  "2022-03-31",
  "2022-05-30",
  "2022-06-04",
  "2022-09-05",
  "2022-10-03",
  "2022-11-11",
  "2022-11-24",
  "2022-12-26",
];

let contractFooter = {
  "64f1e5e7c89d8d4ec138c7f7": "CBS1--6-23. CONTRACT TO BUY AND SELL REAL ESTATE (RESIDENTIAL)", //CBSR
  "665085bc33accdca4c166bc2": "CBS4-6-23. CONTRACT TO BUY AND SELL REAL ESTATE (LAND)", //CBS_LAND
  "6650779933accdca4c166bc1": "MHC1-6-23. MANUFACTURED HOME CONTRACT TO BUY AND SELL", //CBS_MH
  "6650297133accdca4c166bbc": "CBS3-6-23. CONTRACT TO BUY AND SELL REAL ESTATE (COMMERCIAL)", //CBS_CL
  "61d6db2ba0a703f0b609a82f":"LC50-6-22. EXCLUSIVE RIGHT-TO-SELL LISTING CONTRACT", //old ERTS
  "65b9309f0d1a450dd453f546":"LC50-6-23. EXCLUSIVE RIGHT-TO-SELL LISTING CONTRACT", // new  ERTS
  "61d6daf3a0a703f0b609a82e": "BC60-6-22. EXCLUSIVE RIGHT-TO-BUY LISTING CONTRACT",//ERTB
  "6501535ed26ac365812381b8": "BDB24-10-19. BROKERAGE DISCLOSURE TO BUYER DEFINITIONS OF WORKING RELATIONSHIPS",//BDB
  "61d6db4ba0a703f0b609a830":"BDS16-10-19. BROKERAGE DISCLOSURE TO SELLER (FOR SALE BY OWNER)",// BDS
  "61d57046edefd1d5f3505184":"DD25 5-09. DEFINITIONS OF WORKING RELATIONSHIPS",// DWR
  "61bae939bd2d25e6531441f7":"NTC43-6-16. INSPECTION OBJECTION NOTICE",//INSP_OBJ
  "61bae35fbd2d25e6531441f6":"NTC43R-6-16. INSPECTION RESOLUTION (Amendment To Contract To Buy And Sell Real Estate)",//INSP_RESOL buying side
  "65994642f7c56e023238ac1e":"NTC43R-6-16. INSPECTION RESOLUTION (Amendment To Contract To Buy And Sell Real Estate)",// INSP_RESOL selling side
  "61a60f9384644a22bb122e6e":"AVN44-6-16. APPRAISED VALUE OBJECTION NOTICE",//AVO
  "6200d7d8ff3a59c00c894ab9":"TON45-6-16. TITLE-RELATED OBJECTION NOTICE",// TRON
  "61ea4d6134c923aab5238fa0": "LP47-6-21. EARNEST MONEY RELEASE (With Mutual Release)", // EMR 
  "61ea4d7a34c923aab5238fa1":"NTT44-6-21. NOTICE TO TERMINATE",// old buying side NTT
  "62f0a83b6b12b315aed68876":"NTT44-6-21. NOTICE TO TERMINATE", // old selling side NTT
  "65bb61d613d7f6bd9231b13a":"NTT44-6-23. NOTICE TO TERMINATE", // new buying side NTT
  "65bb626913d7f6bd9231b13b":"NTT44-6-23. NOTICE TO TERMINATE",// new selling side NTT
  "61c2a2fa959cd96cb774a1c5":"EMR 83-6-21. AGREEMENT TO AMEND/EXTEND CONTRACT", // ATAE
  "651e693890c8b3006f15aa49":"EMR 83-6-21. AGREEMENT TO AMEND/EXTEND CONTRACT", // ATAES
  "664c6d2add7e610f583432ce":"AEM43-6-23. AGREEMENT TO AMEND/EXTEND CONTRACT", // MH_ATAES
  "6652ec1c1f357cf64d7e9a87":"AEM43-6-23. AGREEMENT TO AMEND/EXTEND CONTRACT", // MH_ATAES
  "64463a7348da0c39129c622c":"SWA35-8-10. SOURCE OF WATER ADDENDUM TO CONTRACT TO BUY AND SELL REAL ESTATE",
  "619d1cf26f79877db4e6afd9":"SF94-5-18. SQUARE FOOTAGE DISCLOSURE (RESIDENTIAL)",// SFD
  "64f97e26494d69c486381500":"SPD19-6-23. SELLER’S PROPERTY DISCLOSURE (RESIDENTIAL)",// SPD
  "61d5832cedefd1d5f350518b":"LP45-6-21. LEAD-BASED PAINT DISCLOSURE (Sales)",// LBPD
  "61ea4d2c34c923aab5238f9e":"LP47-6-21. Lead-Based Paint Obligations (Seller)",// LBPOS
  "6200d79dff3a59c00c894ab8": "AR43-6-19. AGREEMENT TO REVIVE CONTRACT",//ATR
  "61a60f2384644a22bb122e6d":"CL8-5-19. CLOSING INSTRUCTIONS",
  "61ea4d9234c923aab5238fa2":"CP40-6-21. COUNTERPROPOSAL",//CO
  "664c7b65dd7e610f583432d0":"CPM41-6-23. COUNTERPROPOSAL",//MH_CO
  "65e053c738cf5374c0d27a49": "AE42-6-19. LISTING CONTRACT AMEND/EXTEND",//LCAE
  "65eb1b20583b0f7612072602": "AE42-6-19. LISTING CONTRACT AMEND/EXTEND",//LCAES
  "66910e44b98e466f95b62e12": "No. LC57-6-23. EXCLUSIVE RIGHT-TO-LEASE LISTING CONTRACT", // ERTL
  "668ff08e4ca14a238618b3d2": "ETC59-6-22. EXCLUSIVE TENANT LISTING CONTRACT", // ETCT
  "669e1c52a860c19e8f1d5c1e": "AE42-6-19. LISTING CONTRACT AMEND/EXTEND",//LCAES_ERTL
  "669e1d24a860c19e8f1d5c1f": "AE42-6-19. LISTING CONTRACT AMEND/EXTEND",//LCAES_ETCT

  // Correction version use after 15 Aug 2024
  "66aa0f8695a14f0a36fc6e12": "LC50-6-24. EXCLUSIVE RIGHT-TO-SELL LISTING CONTRACT", // ERTS new
  "66ab7a6a7bfcc2a4deedb08d": "CBS1--6-24. CONTRACT TO BUY AND SELL REAL ESTATE (RESIDENTIAL)", // CBSR new
  "66aa1b3b95a14f0a36fc6e13": "No. BC60-6-24. EXCLUSIVE RIGHT-TO-BUY LISTING CONTRACT", // ERTB new
  "66b73993b9f300faedc03840": "CBS3-6-24. CONTRACT TO BUY AND SELL REAL ESTATE (COMMERCIAL)", // CBS_CL new
  "66b74638b9f300faedc03843": "BDB24-6-24. BROKERAGE DISCLOSURE TO BUYER", // BDB new
  "66b75966b9f300faedc03845": "CBSF1--6-24. CONTRACT TO BUY AND SELL REAL ESTATE (RESIDENTIAL) (Colorado Foreclosure Protection Act)", // CBS_COFPA new
  "66b768bab9f300faedc03847": "CBS2-6-24. CONTRACT TO BUY AND SELL REAL ESTATE (INCOME – RESIDENTIAL)", // CBS_IR Income Residential
  "66ba17bf9ebde254a98e1003": "CBS4-6-24. CONTRACT TO BUY AND SELL REAL ESTATE (LAND)", // CBS_LAND new
  "66bb027a61143118c9f6c390": "CP40-6-21. COUNTERPROPOSAL", // Counterproposal new 2024
  "66bb264961143118c9f6c391": "AE41-6-21. AGREEMENT TO AMEND/EXTEND CONTRACT", // ATAE 2024 new
  "66bb671cb14043dcfd2f2477": "AE41-6-21. AGREEMENT TO AMEND/EXTEND CONTRACT", // ATAES 2024 new
  "66ba193b6623b2bab42d88c7": "No. RA33-6-23. RESIDENTIAL ADDENDUM TO CONTRACT TO BUY AND SELL REAL ESTATE", // RESIDENTIAL ADDENDUM new 8 2024
  "TO":"",
  "TR":"",
}
const roleAccessFunc = (roleAccessData, section) => {
  if(!roleAccessData){
    return true;
  }
  let access = false;
  for(const key in roleAccessData){
    if(roleAccessData[key][section]){
        access = true;
        break;
    }
  }

  return access
}
const roleSelectOption = [
  { value: "TRIAL_USER", label: "TRIAL_USER" },
  { value: "AGENT", label: "AGENT" },
  { value: "USER", label: "USER" },
  { value: "REALTOR", label: "REALTOR" },
  { value: "CUSTOMER", label: "CUSTOMER" },
  { value: "TRANSACTION_COORDINATOR", label: "TRANSACTION_COORDINATOR" },
  // { value: "RESURE_SUPPORT", label: "RESURE_SUPPORT" },
  { value: "BROKERAGE_MANAGER", label: "BROKERAGE_MANAGER"}
];
const adminRoleSelectOptionFun = (adminRole) => {

  if (adminRole?.includes("SUPER_ADMIN")) {
    return [
      { value: "SUPER_ADMIN", label: "SUPER_ADMIN" },
      { value: "ADMIN_FRANCHISE", label: "ADMIN_FRANCHISE" },
      // { value: "ADMIN_OFFICE", label: "ADMIN_OFFICE" },
      { value: "ADMIN_BROKERAGE", label: "ADMIN_BROKERAGE" },
      { value: "ADMIN_TITLE_COMPANY", label: "ADMIN_TITLE_COMPANY" },
      { value: "ADMIN_TITLE_OFFICE", label: "ADMIN_TITLE_OFFICE" },
      { value: "RS_SUPPORT", label: "RS_SUPPORT" },
    ];
  } else if (!adminRole?.includes("SUPER_ADMIN") && adminRole?.includes("RS_SUPPORT")) {
    return [
      { value: "ADMIN_FRANCHISE", label: "ADMIN_FRANCHISE" },
      // { value: "ADMIN_OFFICE", label: "ADMIN_OFFICE" },
      { value: "ADMIN_BROKERAGE", label: "ADMIN_BROKERAGE" },
      { value: "ADMIN_TITLE_COMPANY", label: "ADMIN_TITLE_COMPANY" },
      { value: "ADMIN_TITLE_OFFICE", label: "ADMIN_TITLE_OFFICE" },
      { value: "RS_SUPPORT", label: "RS_SUPPORT" },
    ];
  } else {
    return []
  }
}

const commonStatusFunc = ({key}) => {
  switch (key) {
    case "ACCEPTED":
        return {value: "Pending", key: "UNDER_CONTRACT"};
    case "ARCHIVED":
        return {value: "Archived", key: "ARCHIVED"};
    case "DRAFT":
        return {value: "Draft", key: "DRAFT"};
    case "In-Progress":
    case "SUBMITTED":
    case "HOLD":
    case "BACKUP":
    case "INPROGRESS":
        return {value: "In-Progress", key: "INPROGRESS"};
    case "TERMINATED":
        return {value: "Terminated", key: "TERMINATED"};
    case "Active":
    case "Pending":
    case "Active Under Contract":
        return {value: "Active", key: "ACTIVE"};
    case "CLOSED":
    case "Closed":
        return {value: "Closed", key: "CLOSED"};
    default:
        return "";
  }
}


const checkIfDisabled = ({openFor, actualPropertyStatus, offerStatus, buyingStatus, sellingStatus, sellerDocumentStatus, buyingDocumentStatus, contractData}) => {
  const enableArray = contractData?.documentaryId?.enableDuringDisableStatus;
  if((openFor === "SELLER") || (openFor === "SELLER_AGENT")){
    const common = commonStatusFunc({key: actualPropertyStatus});
    if(((common?.key === "CLOSED") && !enableArray?.includes(common?.key)) || ((common?.key === "ARCHIVED") && !enableArray?.includes(common?.key)) || ((sellingStatus === "HOLD") && !enableArray?.includes(sellingStatus)) || ((offerStatus === "TERMINATED") && !enableArray?.includes(offerStatus)) || ((sellerDocumentStatus === "SUBMITTED") && !enableArray?.includes(sellerDocumentStatus))){
      return {
        disabled: true
      }
    }
  }
  else if((openFor === "BUYER") || (openFor === "BUYER_AGENT")){
    const common = commonStatusFunc({key: buyingStatus});
    if(((common?.key === "CLOSED") && !enableArray?.includes(common?.key)) || ((common?.key === "ARCHIVED") && !enableArray?.includes(common?.key)) || ((offerStatus === "TERMINATED") && !enableArray?.includes(offerStatus)) || ((buyingDocumentStatus === "SUBMITTED") && !enableArray?.includes(buyingDocumentStatus))){
      return {
        disabled: true
      }
    }
  }
}

const disabledTransactionText = () => {
  message.warning("Action not available for transactions with status Closed, Archived and Terminated.");
}

function parseValue(value){
  try {
    return  JSON.parse(value);
  } catch (e) {
    return  value;
  }
}
const sellerBrokerFields = ["SellerBrokerAcknowledgeEarnestMoneyReceipt", "SellerBrokerWorkingAs", "SellerBrokerCustomerChecked", "SellerBrokerPaidBy", "SellerBrokerPaidBy__Other"]
const buyerBrokerFields = ["BuyerBrokerAcknowledgeEarnestMoneyReceipt", "BuyerBrokerWorkingAs", "BuyerBrokerCustomerChecked", "BuyerBrokerPaidBy", "BuyerBrokerPaidBy__Other"];
 
module.exports = {
  googleFonts,
  isPublicHoliday,
  listingOrClientContractTypes,
  createOfferContractTypes,
  convertDate,
  decrypt,
  getParsePrice,
  formatPrice,
  purchaseNames,
  daysData,
  getDateData,
  optionsForSort,
  loanTypes,
  optionsForOrder,
  doubleDigitNumber,
  loanTypeData,
  rowDatakeys,
  dateData,
  Table31Inputs,
  convertTime,
  decryptQuery,
  contractFooter,
  roleAccessFunc,
  roleSelectOption,
  adminRoleSelectOptionFun,
  decryptStrQuery,
  noChangeCheckboxContractTypes,
  conterOfferContractTypes,
  amendExtendContractTypes,
  checkIfDisabled,
  disabledTransactionText,
  encryptData,
  parseValue,
  sellerBrokerFields,
  buyerBrokerFields
};
