const getFullNamesFromArray = (people) => {
  let peopleStr = "";
  if (Array.isArray(people)) {
    people.forEach((person, i) => {
      if (person && person.fullName) {
        if (i === 0) {
          peopleStr = person.fullName;
        } else {
          peopleStr += `, ${person.fullName}`;
        }
      } 
    });
    return peopleStr;
  } 
};

export default getFullNamesFromArray;
