import { formatPhoneNumber } from "../../../Common/utils/universalFuntions";

export const addDynamicSellerBuyer = (clientType, clientArray, contractType) => {
    // Title order form
    if (contractType === 'TO') {
        if (clientType === "Sellers") {
            const sellerContainer = document.getElementById('dynamicSellerContainer');
            if (sellerContainer) {
                const sellerDetails = clientArray?.map((seller,i)=>{
                    return `
                        <div class="mt titleCompanyAddressTable">
                            <table class="tableWidth2">
                                <tr>
                                    <td class="leftTable tb">Seller ${i+1}:</td>
                                    <td><input type="text" name="${seller?._id}_SellerFullName" placeholder="Seller FullName" value="${seller?.fullName ? seller?.fullName : ''}"></td>
                                </tr>
                                <tr>
                                    <td>Phone W:</td>
                                    <td><input class="formatPhoneNumber" type="text" name="${seller?._id}_SellerOfficePhoneNumber" placeholder="Seller Office PhoneNumber" value="${seller?.officePhoneNumber ? formatPhoneNumber(seller?.officePhoneNumber) : ''}"></td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td><input type="email" name="${seller?._id}_SellerEmail" placeholder="Seller Email" value="${seller?.email ? seller?.email : ''}"></td>
                                </tr>
                            </table>
                            <table class="tableWidth2">
                                <tr>
                                    <td class="rightTable">Cell:</td>
                                    <td><input class="formatPhoneNumber" type="text" name="${seller?._id}_SellerCellPhoneNo" placeholder="Seller Cell Phone No." value="${seller?.cellPhoneNumber ? formatPhoneNumber(seller?.cellPhoneNumber) : ''}"></td>
                                </tr>
                                <tr>
                                    <td>Phone H:</td>
                                    <td><input class="formatPhoneNumber" type="text" name="${seller?._id}_SellerPhoneNumber" placeholder="Seller PhoneNumber" value="${seller?.phoneNumber ? formatPhoneNumber(seller?.phoneNumber) : ''}"></td>
                                </tr>
                                <tr>
                                    <td>SSN:</td>
                                    <td><input type="text" name="${seller?._id}_SellerSSN" placeholder="Seller SSN Number" value="${seller?.SSN ? seller?.SSN : ''}"></td>
                                </tr>
                                <tr>
                                    <td>Fax:</td>
                                    <td><input class="formatPhoneNumber" type="text" name="${seller?._id}_SellerFaxNo" placeholder="Seller Fax Number" value="${seller?.fax ? formatPhoneNumber(seller?.fax) : ''}"></td>
                                </tr>
                            </table>
                        </div>
                    `
                });
                sellerContainer.innerHTML = sellerDetails.join("\n");
            }
        }
        else if (clientType === "Buyers") {
            const buyerContainer = document.getElementById('dynamicBuyerContainer');
            if (buyerContainer) {
                const buyerDetails = clientArray?.map((buyer,i)=>{
                    return `
                        <div class="mt titleCompanyAddressTable">
                            <table class="tableWidth2">
                                <tr>
                                    <td class="leftTable tb">Buyer ${i+1}:</td>
                                    <td><input type="text" name="${buyer?._id}_BuyerFullName" placeholder="Buyer FullName" value="${buyer?.fullName ? buyer?.fullName : ''}"></td>
                                </tr>
                                <tr>
                                    <td>Home Ph:</td>
                                    <td><input class="formatPhoneNumber" type="text" name="${buyer?._id}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber" value="${buyer?.phoneNumber ? formatPhoneNumber(buyer?.phoneNumber) : ''}"></td>
                                </tr>
                                <tr>
                                    <td>Fax/Work:</td>
                                    <td><input class="formatPhoneNumber" type="text" name="${buyer?._id}_BuyerOfficePhoneNo" placeholder="Buyer Office Ph. No." value="${buyer?.officePhoneNumber ? formatPhoneNumber(buyer?.officePhoneNumber) : ''}"></td>
                                </tr>
                            </table>
                            <table class="tableWidth2">
                                <tr>
                                    <td class="rightTable">Cell Ph:</td>
                                    <td><input class="formatPhoneNumber" type="text" name="${buyer?._id}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No." value="${buyer?.cellPhoneNumber ? formatPhoneNumber(buyer?.cellPhoneNumber) : ''}"></td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td><input type="email" name="${buyer?._id}_BuyerEmail" placeholder="Buyer Email Address" value="${buyer?.email ? buyer?.email : ''}"></td>
                                </tr>
                                <tr>
                                    <td>SSN:</td>
                                    <td><input type="text" name="${buyer?._id}_BuyerSSN" placeholder="Buyer SSN Number" value="${buyer?.SSN ? buyer?.SSN : ''}"></td>
                                </tr>
                            </table>
                        </div>
                    `
                });
                buyerContainer.innerHTML = buyerDetails.join("\n");
            }
        }
    }
    
    // Transaction Report form
    else if (contractType === 'TR') {
        if (clientType === "Sellers") {
            const sellerContainer = document.getElementById('dynamicSellerContainer');
            if (sellerContainer) {
                let count = 0;
                const sellerDetails = clientArray?.map((seller,i)=>{
                    count = i+1;
                    if (seller?.isCorp && seller?.signers) {
                        return seller?.signers?.map((signer,j) => {
                            count = count +j;
                            return `
                                <div class="mt CompanyAddressTable">
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="leftTable tb">Seller ${count}:</td>
                                            <td><input type="text" name="${signer?._id}_SellerFullName"
                                                    placeholder="Seller FullName"
                                                    value="${signer?.fullName ? signer?.fullName : ''} [${seller?.fullName ? seller?.fullName : ''}]" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><input type="text" name="${signer?._id}_SellerEmail"
                                                    placeholder="Seller Email" value="${signer?.email ? signer?.email : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>SSN:</td>
                                            <td><input type="text" name="${signer?._id}_SellerSSN"
                                                    placeholder="Seller SSN Number"
                                                    value="${signer?.SSN ? signer?.SSN : ''}" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Address:</td>
                                            <td><input type="text" name="SellerAddress" placeholder="Seller Address" disabled></td>
                                        </tr>
                                    </table>
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="rightTable">Home Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?._id}_SellerPhoneNumber" placeholder="Seller PhoneNumber"
                                                    value="${signer?.phoneNumber ? formatPhoneNumber(signer?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Fax/Work:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${seller?._id}_SellerOfficePhoneNumber"
                                                    placeholder="Seller Office PhoneNumber"
                                                    value="${seller?.officePhoneNumber ? formatPhoneNumber(seller?.officePhoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${seller?._id}_SellerCellPhoneNo"
                                                    placeholder="Seller Cell Phone No."
                                                    value="${seller?.cellPhoneNumber ? formatPhoneNumber(seller?.cellPhoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                    </table>
                                </div>
                            `
                        }).join('');
                    } else {
                        return `
                            <div class="mt CompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Seller ${count}:</td>
                                        <td><input type="text" name="${seller?._id}_SellerFullName"
                                                placeholder="Seller FullName"
                                                value="${seller?.fullName ? seller?.fullName : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="text" name="${seller?._id}_SellerEmail"
                                                placeholder="Seller Email" value="${seller?.email ? seller?.email : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${seller?._id}_SellerSSN"
                                                placeholder="Seller SSN Number"
                                                value="${seller?.SSN ? seller?.SSN : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Address:</td>
                                        <td><input type="text" name="SellerAddress" placeholder="Seller Address" disabled></td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Home Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${seller?._id}_SellerPhoneNumber" placeholder="Seller PhoneNumber"
                                                value="${seller?.phoneNumber ? formatPhoneNumber(seller?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${seller?._id}_SellerOfficePhoneNumber"
                                                placeholder="Seller Office PhoneNumber"
                                                value="${seller?.officePhoneNumber ? formatPhoneNumber(seller?.officePhoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Mobile Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${seller?._id}_SellerCellPhoneNo"
                                                placeholder="Seller Cell Phone No."
                                                value="${seller?.cellPhoneNumber ? formatPhoneNumber(seller?.cellPhoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                </table>
                            </div>
                        `
                    }
                });
                sellerContainer.innerHTML = sellerDetails.join("\n");
            }
        }
        else if (clientType === "Buyers") {
            const buyerContainer = document.getElementById('dynamicBuyerContainer');
            if (buyerContainer) {
                let count = 0;
                const buyerDetails = clientArray?.map((buyer,i)=>{
                    count = i+1;
                    if (buyer?.isCorp && buyer?.signers) {
                        return buyer?.signers?.map((signer,j) => {
                            count = count+j
                            return `
                                <div class="mt CompanyAddressTable">
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="leftTable tb">Buyer ${count}:</td>
                                            <td><input type="text" name="${signer?._id}_BuyerFullName" placeholder="Buyer FullName"
                                                    value="${signer?.fullName ? signer?.fullName : ''} [${buyer?.fullName ? buyer?.fullName : ''}]" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><input type="text" name="${signer?._id}_BuyerEmail"
                                                    placeholder="Buyer Email Address"
                                                    value="${signer?.email ? signer?.email : ''}" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>SSN:</td>
                                            <td><input type="text" name="${signer?._id}_BuyerSSN"
                                                    placeholder="Buyer SSN Number" value="${signer?.SSN ? signer?.SSN : ''}"
                                                    disabled></td>
                                        </tr>
        
                                        <tr>
                                            <td>Address:</td>
                                            <td><input type="text" name="BuyerAddress" placeholder="Buyer Address" disabled>
                                            </td>
                                        </tr>
                                    </table>
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="rightTable">Home Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?._id}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber"
                                                    value="${signer?.phoneNumber ? formatPhoneNumber(signer?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Fax/Work:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?._id}_BuyerOfficePhoneNo"
                                                    placeholder="Buyer Office Ph. No."
                                                    value="${signer?.officePhoneNumber ? formatPhoneNumber(signer?.officePhoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?._id}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No."
                                                    value="${signer?.cellPhoneNumber ? formatPhoneNumber(signer?.cellPhoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
        
                                    </table>
                                </div>
                            `
                        }).join('');
                    } else {
                        return `
                            <div class="mt CompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Buyer ${count}:</td>
                                        <td><input type="text" name="${buyer?._id}_BuyerFullName" placeholder="Buyer FullName"
                                                value="${buyer?.fullName ? buyer?.fullName : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="text" name="${buyer?._id}_BuyerEmail"
                                                placeholder="Buyer Email Address"
                                                value="${buyer?.email ? buyer?.email : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${buyer?._id}_BuyerSSN"
                                                placeholder="Buyer SSN Number" value="${buyer?.SSN ? buyer?.SSN : ''}"
                                                disabled></td>
                                    </tr>

                                    <tr>
                                        <td>Address:</td>
                                        <td><input type="text" name="BuyerAddress" placeholder="Buyer Address" disabled>
                                        </td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Home Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${buyer?._id}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber"
                                                value="${buyer?.phoneNumber ? formatPhoneNumber(buyer?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${buyer?._id}_BuyerOfficePhoneNo"
                                                placeholder="Buyer Office Ph. No."
                                                value="${buyer?.officePhoneNumber ? formatPhoneNumber(buyer?.officePhoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Mobile Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${buyer?._id}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No."
                                                value="${buyer?.cellPhoneNumber ? formatPhoneNumber(buyer?.cellPhoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
    
                                </table>
                            </div>
                        `
                    }
                });
                buyerContainer.innerHTML = buyerDetails.join("\n");
            }
        }
    }
}