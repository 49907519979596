import moment from "moment";

export const transactionReportDynamicFields = (getTransactionReportData, type) => {
    if (type === 'DOCUMENTS') {
        const resureTRDocuments = document.getElementById('resureTRDocuments');
        if (resureTRDocuments) {
            resureTRDocuments.innerHTML = getTransactionReportData?.map((doc) => {
                if (doc.contractType === "TR") return;
                return `
                    <tr>
                        <td>${doc.RTDInitiator}</td>
                        <td>${doc.contractName}</td>
                        <td>${doc.buyerDocumentStatus}</td>
                        <td>${doc.sellerDocumentStatus}</td>
                    </tr>
                `
            }).join('');
        }
    } else if (type === 'SIGNATURE_DETAILS') {
        const signatureDetailsTR = document.getElementById('signatureDetailsTR');
        if (signatureDetailsTR) {
            const submittedDocsHTML = getTransactionReportData
                ?.filter(ele => ele?.buyerDocumentStatus === 'SUBMITTED' || ele?.sellerDocumentStatus === 'SUBMITTED')
                ?.map(row => {
                    return row?.signatureInfo?.map(signer => `
                        <tr>
                            <td>${row?.contractType}</td>
                            <td>
                                <div style="font-weight: bold">${signer?.fullName}</div>
                                <div style="color: rgb(113, 111, 111); font-size: small;">${signer?.email}</div>
                                <div style="color: rgb(113, 111, 111); font-size: small;">${signer?.key}</div>
                            </td>
                            <td>${signer?.roleType}</td>
                            <td>
                            <div>${signer?.ip?.region}/${signer?.ip?.country}</div>
                                <div style="color: rgb(113, 111, 111); font-size: small;">(${signer?.ip?.latitude},${signer?.ip?.longitude})</div>
                            </td>
                            <td>
                                <div>${moment(signer?.timeStamp).format("MMMM D, YYYY hh:mm A")}</div>
                                <div style="color: rgb(113, 111, 111); font-size: small;">${signer?.ip?.timezone?.name}</div>
                            </td>
                            <td>${signer?.ip?.security?.is_vpn ? "Yes" : "No"}</td>
                        </tr>
                    `).join('');
                }).join('');
            signatureDetailsTR.innerHTML = submittedDocsHTML;
        }
    } else if (type === 'EMAIL_COMMUNICATIONS') {
        const emailCommunicationsSection_tbody = document.getElementById('emailCommunicationsSection_tbody');
        if (emailCommunicationsSection_tbody && getTransactionReportData && Array.isArray(getTransactionReportData)) {
            emailCommunicationsSection_tbody.innerHTML = getTransactionReportData?.map((record) => {
                return `
                    <tr>
                        <td class="clickable" data-TR-expand-table>
                            <div style="display: flex;">
                                <span class="dropdown-icon"><i
                                        class="bi bi-caret-right-fill"></i></span>
                                <span style="width: 100%; font-weight: bold;">${record?.from}</span>
                            </div>
                            <div class="list-container">
                                <ul>
                                    ${record?.originalTo?.map((email)=> {return `<li class="dropDownContent">${email}</li>`}).join("")}
                                    ${record?.originalCc?.map((email)=> {return `<li class="dropDownContent">${email}</li>`}).join("")}
                                    ${record?.forwardedTo?.map((email)=> {return `<li class="dropDownContent">${email}</li>`}).join("")}
                                </ul>
                            </div>
                        </td>
                        <td class="subjectColumn clickable" data-TR-expand-table>${record?.subject}</td>
                        <td>${moment(record?.forwardedDate).format("MMMM D, YYYY hh:mm A")}</td>
                        <td><div class="clickable" style="text-decoration: underline; color: -webkit-link;" data-open-email=${JSON.stringify({s3Bucket: record.s3Bucket, s3Key: record.s3Key, logId: record._id, originalCc: record?.originalCc, forwardedTo: record?.forwardedTo })}>View Email</div></td>
                    </tr>
                `
            }).join('');
        }
    } else if (type === 'SHARED_DOCS') {
        const sharedDocumentSection_tbody = document.getElementById('sharedDocumentSection_tbody');
        if (sharedDocumentSection_tbody && getTransactionReportData) {
            sharedDocumentSection_tbody.innerHTML = getTransactionReportData?.map((record) => {
                return `
                    <tr>
                        <td>WEB</td>
                        <td>
                            <span style="display: flex; justify-content: left; align-items: center; gap: 5px;">
                                ${
                                    record?.fileType === 'IMAGE' ? '<i class="bi bi-file-earmark-image" style="color: #711ae1;"></i>' : 
                                    record?.fileType === 'PDF' ? '<i class="bi bi-file-earmark-pdf" style="color: red;"></i>' :
                                    '<i class="bi bi-file-earmark-text" style="color: #74b3ed;"></i>'
                                }
                                ${record?.fileName}
                            </span>
                        </td>
                        <td>${record?.fileType}</td>
                        <td>${moment(record?.sentDateMili).format("MMMM D, YYYY hh:mm A")}</td>
                        <td>${record?.sharedBy?.fullName}</td>
                        <td><a href="${record?.fileUrl}" target="_blank">Open File Link</a></td>
                    </tr>
                `
            }).join('');
        }
    }
}