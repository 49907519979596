/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Table, Badge, Input, Space, Card, Button, Select, Modal, Tooltip, Form, DatePicker, message, Empty, List, Spin, Menu, Dropdown, Typography, Switch } from "antd";
import { cmsDataHandle } from "../state/actions";
import Highlighter from "react-highlight-words";
import { EditTwoTone, } from "@ant-design/icons";
import {ExclamationCircleOutlined, SearchOutlined, CloseCircleOutlined, CheckCircleOutlined, FilterFilled, MoreOutlined } from "@ant-design/icons";
import { officeTypeHandle } from "../../OfficeType/state/actions";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import { Signup } from "../../Authenticate/components/Signup";
import { adminRoleSelectOptionFun, roleAccessFunc, roleSelectOption } from "../../Common/commondata/commonData";
import { useEffect } from "react";
import { adminAction } from "../../Authenticate/state/actions";
import BrokerageDetails from "../../Authenticate/components/BrokerageDetails";
import SubsCriptionDetails from "./SubscriptionDeatils";
import { generateFullNameFromObj } from "../../Common/utils/universalFuntions";
import LicenseDetails from "./LicenseDetails";
import EmailDetailsCol from "./EmailDetailsCol";
const { confirm } = Modal;
const { Option } = Select;
function TablbeView() {
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [selectedFilterValue, setSelectedFilterValue] = useState({order:"",isActive:"",role:[],adminRole:[]});
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [showCreateManualSubMoal, setShowCreateManualSubMoal] = useState({
    visible: false,
    personId: "",
  });
  const [rolesArray, setRolesArray] = useState(false);
  const [disableBtn, setdisableBtn] = useState(true);
  const [sortedInfo, setSortedInfo] = useState("ascend");
  const [startDate, setStartDate] = useState("");
  const [popConfirmVisible, setPopConfirmVisible] = useState({
    visible: false,
  });
  const personIdForUpdate = useRef("");
  const roleModalFor = useRef("");
  const {
    personData,
    totalItems,
    personLoading,
    associationData,
    associationDataLoading,
    associationDataError,
    addAssociationLoading,
    viewSignupLinkLoading,
    viewSignupLinkData,
    viewSignupLinkDataError,
    toggleLoading,
  } = useSelector((state) => state.cms);
  const searchRef = useRef(false);
  const [reason, setReason] = useState("");
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [selectedAssociation, setSelectedAssociation] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [brokerageInput, setBrokerageInput] = useState("");
  const [openAssociationModal, setOpenAssociationModal] = useState(false);
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual,
  );
  const { setBrokerageModal } = useSelector((state) => state.authenticate);
  const [currentSelectedFranchise, setCurrentSelectedFranchise] = useState("");
  const [officeInput, setOfficeInput] = useState("");
  const [query, setQuery] = useState({});
  const handleConfirmRemovePlan = () => {
    let data = { planId: popConfirmVisible?.planId, manualNotes: reason };
    if (reason.trim() !== "") {
      dispatch(
        cmsDataHandle.deleteSubcription(data),
      );
      form.resetFields();
      setReason("");
      setPopConfirmVisible({ visible: false });
    }
  };
  // Make the person active and deactive
  const handleActiveConfirm = (event, ele) => {
    confirm({
      title: event.isActive
        ? `Are you sure you want to make ${event.fullName}'s account Inactive`
        : `Are you sure you want to make ${event.fullName}'s account Active`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(cmsDataHandle.changeStatusData(event._id, event.isActive));
      },
      onCancel() {},
    });
  };


const hadndleResetAll=()=>{
    searchRef.current = true;
    let queryData = {
      page: 1,
    };
    dispatch(cmsDataHandle.getPersonsData({}));
    setQuery(queryData);
    setsearchText("");
    setSelectedFilterValue(null);
    setCurrentPage(1)
    searchRef.current = false;
};

const handleReset = (clearFilters, setSelectedKeys, dataIndex) => {

    searchRef.current = true;
    let queryData = { ...query };

    if (dataIndex === 'email') {
      delete queryData.email;
    } else if (dataIndex === 'fullName') {
      delete queryData.fullName;
    } else if (dataIndex === 'createdAt') {
      delete queryData.sortBy;
      delete queryData.order;
    } else if (dataIndex === 'adminRole') {
      delete queryData.adminRole;
    } else if (dataIndex === 'role') {
      delete queryData.role;
    } else if (dataIndex === 'isActive') {
      delete queryData.isActive;
    }

    clearFilters();
    setSelectedKeys("");
    setSelectedFilterValue(null);
    queryData.page = currentPage;
    queryData.limit = 30;
    dispatch(cmsDataHandle.getPersonsData(queryData));
    setQuery(queryData);
    setsearchText("");
    setsearchedColumn('');
    searchRef.current = false;
  };


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    searchRef.current = true;
    setsearchText(selectedKeys[0]);
    let queryData = {
    ...query,
      [dataIndex]: selectedKeys[0],
      page: currentPage,
      limit: 30,
    };
    dispatch(cmsDataHandle.getPersonsData(queryData));
    setQuery(queryData);
    setsearchedColumn(dataIndex);
    searchRef.current = false;
  };

  const handleSort = (selectedKeys, confirm, dataIndex) =>{
    confirm();
    searchRef.current = true;
    let queryData = {
        ...query,
        sortBy:dataIndex,
        order: selectedFilterValue?.order,
        page: currentPage,
        limit: 30,
      };
      setQuery(queryData);
      dispatch(cmsDataHandle.getPersonsData(queryData));
      setSelectedFilterValue(null);
      searchRef.current = false;
  }

  const handleStatus = (selectedKeys, confirm, dataIndex) =>{
    confirm();
    searchRef.current = true;
    let queryData = {
        ...query,
        isActive:selectedFilterValue && selectedFilterValue?.isActive === "ACTIVE" ? true : false,
        page: currentPage,
        limit: 30,
      };
      setQuery(queryData);
      dispatch(cmsDataHandle.getPersonsData(queryData));
      setSelectedFilterValue(null);
      searchRef.current = false;
  }

  const handleRole = (selectedKeys, confirm, dataIndex) =>{
    confirm();
    searchRef.current = true;
    let queryData = {
        ...query,
        role:selectedFilterValue?.role,
        page: currentPage,
        limit: 30,
      };
      setQuery(queryData);
      dispatch(cmsDataHandle.getPersonsData(queryData));
      setSelectedFilterValue(null);
      searchRef.current = false;
  }

  const handleAdminRole = (selectedKeys, confirm, dataIndex) =>{
    confirm();
    searchRef.current = true;
    let queryData = {
        ...query,
        adminRole:selectedFilterValue?.adminRole,
        page: currentPage,
        limit: 30,
      };
      setQuery(queryData);
      dispatch(cmsDataHandle.getPersonsData(queryData));
      setSelectedFilterValue(null);
      searchRef.current = false;
  }
  const handleRoleOk = () => {
    let query = {
      page: currentPage,
      limit: 30,
    };
    if (roleModalFor.current === "ROLE") {
      dispatch(
        officeTypeHandle.updatePerson(
          { personId: personIdForUpdate?.current, roles: rolesArray },
          "ADMIN_ROLE_UPDATE",
          query,
        ),
      );
    } else {
      dispatch(
        officeTypeHandle.updatePerson(
          { personId: personIdForUpdate?.current, adminRole: rolesArray },
          "ADMIN_ROLE_UPDATE",
          query,
        ),
      );
    }
    setIsRoleModalOpen(false);
    setRolesArray([]);
  };

  const handleRoleCancel = () => {
    setIsRoleModalOpen(false);
    setRolesArray([]);
  };

  const handleEditIconClick = (record,toggle, source,planId,startDate,endDate,notes,plan) => {

    setShowCreateManualSubMoal({
        activePlan: toggle === true ? true : false,
        personId: record?._id,
        visible: true,
        startDate,
        endDate,
        planId,
        source,
        notes,
        plan,
    });

  };

  const handleSubcriptionCancel = () => {
    form.resetFields();
    setShowCreateManualSubMoal({ visible: false, personId: "" });
  };

  const handleAddSubcription = (data) => {
    dispatch(
      cmsDataHandle.handleSubcription(data, showCreateManualSubMoal.personId),
    );
    setShowCreateManualSubMoal({ visible: false, personId: "" });
    form.resetFields();
  };

  const handleEditSubscription =(data)=>{

    dispatch(
        cmsDataHandle.editSubcription(data, showCreateManualSubMoal.planId),
      );
      setShowCreateManualSubMoal({ visible: false, personId: "" });
      form.resetFields();
  };

  const handleRoleChange = (el) => {
    setRolesArray(el);
  };

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [sendSignupEmail, setSendSignupEmail] = useState(false);
  const [viewSignupLink, setViewSignupLink] = useState(false);


  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            searchRef.current = true;
            handleSearch(selectedKeys, confirm, dataIndex);
            setdisableBtn(false);
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              searchRef.current = true;
              handleSearch(selectedKeys, confirm, dataIndex);
              setdisableBtn(false);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters, setSelectedKeys,dataIndex);
              setdisableBtn(true);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let getadminRoleProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            value={selectedFilterValue?.adminRole || []}
            placeholder="Select Role"
            onChange={(role, event) => {
            setSelectedFilterValue({...setSelectedFilterValue,adminRole:role});
            }}
            options={[
              {
                value: "ADMIN_FRANCHISE",
                label: "ADMIN_FRANCHISE",
              },
              {
                value: "ADMIN_BROKERAGE",
                label: "ADMIN_BROKERAGE",
              },
              // {
              //   value: "ADMIN_OFFICE",
              //   label: "ADMIN_OFFICE",
              // },
              {
                value: "SUPER_ADMIN",
                label: "SUPER_ADMIN",
              },
              {
                value: "RS_SUPPORT",
                label: "RS_SUPPORT",
              },
            ]}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => {
            handleAdminRole(selectedKeys, confirm, dataIndex)
              setdisableBtn(false);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters, setSelectedKeys,dataIndex);
              setdisableBtn(true);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let getstatus = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Status"
            value={selectedFilterValue?.isActive}
            onChange={(role, event) => {
            setSelectedFilterValue({...setSelectedFilterValue,isActive:role});
            }}
            options={[
              {
                value: "ACTIVE",
                label: "ACTIVE",
              },
              {
                value: "DEACTIVATED",
                label: "DEACTIVATED",
              },
            ]}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => {
            //   dispatch(cmsDataHandle.filterIsActive(selectedFilterValue));
            handleStatus(selectedKeys, confirm, dataIndex);
              setdisableBtn(false);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
                clearFilters && handleReset(clearFilters, setSelectedKeys,dataIndex);
              setdisableBtn(true);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let getRoleProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select Role"
            value={selectedFilterValue?.role || []}
            onChange={(role, event) => {
            //   setSelectedFilterValue(role);
            setSelectedFilterValue({...setSelectedFilterValue,role:role});
            }}
            options={[
              {
                value: "USER",
                label: "USER",
              },
              {
                value: "AGENT",
                label: "AGENT",
              },
              {
                value: "REALTOR",
                label: "REALTOR",
              },
              {
                value: "CUSTOMER",
                label: "CUSTOMER",
              },
              {
                value: "TRANSACTION_COORDINATOR",
                label: "TRANSACTION_COORDINATOR",
              },
            ]}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => {
            //   dispatch(cmsDataHandle.filterRole(selectedFilterValue, "adminRole"));
            handleRole(selectedKeys, confirm, dataIndex)
              setdisableBtn(false);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters, setSelectedKeys,dataIndex);
              setdisableBtn(true);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let getSort = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Order"
            value={selectedFilterValue?.order}
            onChange={(role, event) => {
              setSelectedFilterValue({...setSelectedFilterValue,order:role});
            }}
            options={[
                {
                  value: "ASC",
                  label: "ASCENDING",
                },
                {
                  value: "DESC",
                  label: "DESCENDING",
                },
              ]}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSort(selectedKeys, confirm, dataIndex);
              setdisableBtn(false);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters, setSelectedKeys,dataIndex);
              setdisableBtn(true);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter.order);
  };

  const handleAccessAccount = (ele) => {
    dispatch(cmsDataHandle.loginAnotherAccount({delegateOwnerId: ele?._id}, true));
  }

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      fixed: 'left',
      ...getColumnSearchProps("fullName"),
      sorter: (a, b) => {
        const aDate = a.loginDetails?.lastLoginAt || 0;
        const bDate = b.loginDetails?.lastLoginAt || 0;
        return aDate - bDate;
      },
      showSorterTooltip: {
        title:
          sortedInfo === "ascend"
            ? "Click to sort by last login (ascending)."
            : sortedInfo === "descend"
              ? "Click to sort by last login (descending)."
              : "Click to cancel sorting",
      },
      ellipsis: true,
      align: "center",
      width: 250,
      render: (fullName, record) => (
        <span>
          <div>{generateFullNameFromObj(record)}</div>
          {record?.loginDetails?.lastLoginAt ? (
            <>
              {" "}
              <Text>Last Login At :</Text>
              <div>
                <Text style={{ color: "#29be" }}>
                  {moment(record?.loginDetails?.lastLoginAt).format(
                    "MMMM D, YYYY hh:mm A",
                  )}
                </Text>
              </div>
            </>
          ) : null}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ...getSort("createdAt"),
      render: (createdAt, i) => (
        <Text>{moment(createdAt).format("MMMM D, YYYY hh:mm A")}</Text>
      ),
      align: "center",
      defaultSortOrder: "descend",
      width: 200,
    },
    {
      title: "Email",
      key: "email",
      width: "300px",
      ...getColumnSearchProps("email"),
      render: (record) => (
        <EmailDetailsCol record={record} currentPage={currentPage } />
      ),
      ellipsis: true,
      align: "center",
    },
    {
      title: "Status",
      width: "200px",
      key: "isActive",
      ...getstatus("isActive"),
      render: (event, record) => (
        <span>
          <Badge status={event.isActive ? "success" : "error"} />
          {event.isActive ? "Active" : "Deactivated"}
          <Button
            key={record._id}
            onClick={() => handleActiveConfirm(event, record)}
            icon={
              event.isActive ? <CloseCircleOutlined /> : <CheckCircleOutlined />
            }
            size="large"
            shape="round"
            style={{ marginLeft: "18px" }}
          />
        </span>
      ),
      align: "center",
    },
    {
      title: "Access Account",
      key: "access",
      //   dataIndex:"role",
      width: 200,
      render: (ele) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <button
            style={{ flex: '0 0 auto', marginLeft: '10px', padding: '4px', lineHeight: '13px', cursor: 'pointer', fontSize: '15px' }}
            onClick={() => handleAccessAccount(ele)}
          >
            Access Account
          </button>
        </div>
      ),
      align: "center",
    },
    {
      title: "Role",
      key: "roles",
      //   dataIndex:"role",
      width: 200,
      ...getRoleProps("role"),
      render: (ele) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {ele?.roles ? (
              ele?.roles?.map((role, i) => (
                <div key={i}>
                  {role === "TRANSACTION_COORDINATOR"
                    ? "TRANS_COORDINATOR"
                    : role}
                </div>
              ))
            ) : (
              <p>NA</p>
            )}
          </div>
          {ele.isEmailVerified && (
            <p
              onClick={() => {
                setRolesArray(ele?.roles || []);
                setIsRoleModalOpen(true);
                personIdForUpdate.current = ele?._id;
                roleModalFor.current = "ROLE";
              }}
              style={{ cursor: "pointer", marginTop: "13px" }}
            >
              <EditTwoTone />
            </p>
          )}
        </div>
      ),
      align: "center",
    },
    {
      title: "Admin Role",
      dataIndex: "adminRole",
      key: "update",
      width: 250,
      ...getadminRoleProps("adminRole"),
      render: (adminRole, ele) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {adminRole ? adminRole?.join(" ") : <p>NA</p>}
          </div>
          {ele.isEmailVerified && (
            <p
              onClick={() => {
                setRolesArray(adminRole || []);
                setIsRoleModalOpen(true);
                personIdForUpdate.current = ele?._id;
                roleModalFor.current = "ADMIN_ROLE";
              }}
              style={{ cursor: "pointer", marginTop: "13px" }}
            >
              <EditTwoTone />
            </p>
          )}
        </div>
      ),
      align: "center",
    },
    {
      title: "Association",
      dataIndex: "associationData",
      key: "associationData",
      width: 250,
      render: (associationData, ele) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {associationData?.label ? associationData.label : <p>NA</p>}
          </div>
          <p
            onClick={() => {
              setOpenAssociationModal(true);
              personIdForUpdate.current = ele?._id;
              roleModalFor.current = "ASSOCIATION";
            }}
            style={{ cursor: "pointer", marginTop: "13px" }}
          >
            <EditTwoTone />
          </p>
        </div>
      ),
      align: "center",
    },
    {
      title: "Brokerage",
      dataIndex: "officeData",
      key: "officeData",
      width: 350,
      render: (officeData, ele) =>
        // officeData ? (
        //   <Badge.Ribbon
        //     key={ele}
        //     text={officeData?.isVerified ? "Verified" : "Not Verified"}
        //     color={officeData?.isVerified ? "#52c41a" : "#f50"}
        //     placement={officeData?.isVerified ? "start" : "end"}
        //   >
        //     <div style={{ paddingTop: "1rem" }}>
        //       <Card>
        //         <div
        //           style={{ display: "flex", justifyContent: "space-between" }}
        //         >
        //           <div>
        //             <div
        //               style={{
        //                 display: "flex",
        //                 alignItems: "center",
        //                 textAlign: "left",
        //               }}
        //             >
        //               <p
        //                 style={{
        //                   marginBottom: 0,
        //                   marginRight: 10,
        //                   color: "orange",
        //                 }}
        //               >
        //                 Name:
        //               </p>
        //               <Text style={{ marginBottom: 0, width: "200" }} ellipsis>
        //                 {ele?.brokerageData ? ele?.brokerageData?.brokerageName
        //                   : "NA"}
        //               </Text>
        //             </div>
        //             <div
        //               style={{
        //                 display: "flex",
        //                 alignItems: "center",
        //                 textAlign: "left",
        //               }}
        //             >
        //               <p
        //                 style={{
        //                   marginBottom: 0,
        //                   marginRight: 10,
        //                   color: "orange",
        //                 }}
        //               >
        //                 Office:
        //               </p>
        //               <Text style={{ marginBottom: 0, width: 200 }} ellipsis>
        //                 {officeData?.officeId?.name
        //                   ? officeData?.officeId.name
        //                   : "NA"}
        //               </Text>
        //             </div>
        //           </div>
        //           <p
        //             onClick={() => {
        //               // dispatch(adminAction.openBrokerageSelectModal(true));
        //               setOpenAssociationModal(true);
        //               personIdForUpdate.current = ele?._id;
        //               roleModalFor.current = "BROKERAGE";
        //             }}
        //             style={{
        //               cursor: "pointer",
        //               marginTop: "13px",
        //               marginBottom: 0,
        //             }}
        //           >
        //             <EditTwoTone />
        //           </p>
        //         </div>
        //       </Card>
        //     </div>
        //   </Badge.Ribbon>
        // ) : (
          <div style={{ paddingTop: "1rem" }}>
            <Card>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: 0,
                        marginRight: 10,
                        color: "orange",
                      }}
                    >
                      Brokerage:
                    </p>
                    <Text style={{ marginBottom: 0, width: "200" }} ellipsis>
                      {ele?.brokerageData
                        ? ele?.brokerageData?.brokerageName
                        : "NA"}
                    </Text>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: 0,
                        marginRight: 10,
                        color: "orange",
                      }}
                    >
                      Office:
                    </p>
                    <Text style={{ marginBottom: 0, width: 200 }} ellipsis>
                      {officeData?.officeId?.name
                        ? officeData?.officeId.name
                        : "NA"}
                    </Text>
                  </div> */}
                </div>
                {/* <p
                  onClick={() => {
                    // dispatch(adminAction.openBrokerageSelectModal(true));
                    setOpenAssociationModal(true);
                    personIdForUpdate.current = ele?._id;
                    roleModalFor.current = "BROKERAGE";
                  }}
                  style={{
                    cursor: "pointer",
                    marginTop: "13px",
                    marginBottom: 0,
                  }}
                >
                  <EditTwoTone />
                </p> */}
              </div>
            </Card>
          </div>,
        // ),
      align: "center",
    },

    {
      title: "Plan Information",
      key: "activePlan",
      // ellipsis: true,
      align: "center",
      width: 350,
      render: (record) => (
        <div>
            {SubsCriptionDetails({record,handleEditIconClick, setPopConfirmVisible})}
        </div>
      ),
    },
    {
      title: "License Information",
      key: "licenseData",
      align: "center",
      width: 350,
      render: (record) => (
      <LicenseDetails  record={record}/>
      ),
    },
   
  ];

  const handleCancelModal = () => {
    form.resetFields();
    setShowCreateModal(false);
    setBrokerageInput("");
    setOfficeInput("");
  };

  const handleCancel = () => {
    form.resetFields();
    setSendSignupEmail(false);
    setSelectedAssociation({});
  };

  const handleCancelAssociationData = () => {
    setSelectedAssociation({});
    setIsModalVisible(false);
  };

  const handleCancelViewSignupLink = () => {
    setViewSignupLink(false);
  };

  const handleAssociation = () => {
    dispatch(cmsDataHandle.getAssociationData());
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (selectedAssociation?.label?.length > 0) {
      form.setFieldsValue({ association: selectedAssociation?.label });
    } else {
      form.setFieldsValue({ association: undefined });
    }
  }, [selectedAssociation]);


  useEffect(() => {
    const timer = setTimeout(() => {
    form.setFieldsValue({
        startDate: showCreateManualSubMoal?.startDate
          ? moment(showCreateManualSubMoal?.startDate)
          : "",
        endDate: showCreateManualSubMoal?.endDate
          ? moment(showCreateManualSubMoal?.endDate)
          : "",
        manualNotes:showCreateManualSubMoal?.notes,
        activePlan: showCreateManualSubMoal?.plan,
      });
    }, 100);

    return () => clearTimeout(timer);
  }, [showCreateManualSubMoal, form]);

  const handleFinish = (value) => {
    const associationKey = selectedAssociation ? selectedAssociation.key : "";
    const data = {
      associationKey: associationKey,
    };
    dispatch(cmsDataHandle.addAssociation(data, undefined, handleCancel));
  };

  const MenuItem = ({ item, tempId }) => {
    return roleAccessed &&
      adminRole &&
      roleAccessFunc(roleAccessed, "CREATE_USER") ? (
      <Menu size="small">
        <>
          <Menu.Item
            style={{
              fontWeight: "500",
              fontSize: "15px",
              display: "flex",
              alignItems: "center",
            }}
            key="editCreate"
            onClick={() => {
              setShowCreateModal(true);
            }}
          >
            Create User
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: "500",
              fontSize: "15px",
              display: "flex",
              alignItems: "center",
            }}
            key="send"
            onClick={() => {
              setSendSignupEmail(true);
            }}
          >
            Add Sign-up Link
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: "500",
              fontSize: "15px",
              display: "flex",
              alignItems: "center",
            }}
            key="view"
            onClick={() => {
              setViewSignupLink(true);
              dispatch(cmsDataHandle.getViewSignupLinkData());
            }}
          >
            View Sign-up Links
          </Menu.Item>
        </>
      </Menu>
    ) : (
      <></>
    );
  };

  const handleToggle = ({ value, associationId, redirectSignupLinkId }) => {
    const payload = {
      updateActive: true,
      isActive: value,
      associationId,
      redirectSignupLinkId,
    };
    dispatch(cmsDataHandle.handleToggleFunc(payload));
  };

  const handleModalCancel = () => {
    setOpenAssociationModal(false);
    setOfficeInput("");
    setBrokerageInput("");
    setSelectedAssociation({});
    setCurrentSelectedFranchise("");
  };

  const handleOk = (handleFor) => {
    let payload = {};
    if (handleFor === "ASSOCIATION") {
      payload.association = selectedAssociation;
    } else if (handleFor === "BROKERAGE") {
      payload.officeId = currentSelectedFranchise._id;
    }
    dispatch(
      cmsDataHandle.sendOrVerifyEmail({
        id: personIdForUpdate?.current,
        payload,
      }),
    );

    // Clear all states
    setOpenAssociationModal(false);
    setOfficeInput("");
    setBrokerageInput("");
    setSelectedAssociation({});
    setCurrentSelectedFranchise("");
  };

  const handleOptionChange = (value) => {
    setSelectedAssociation(value);
  };

  const callAssociation = () => {
    dispatch(cmsDataHandle.getAssociationData());
  };

  return (
    <>
      {toggleLoading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "black",
            opacity: "0.5",
            zIndex: "5000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        ""
      )}

      <Modal
        visible={showCreateModal}
        style={{ top: 20 }}
        width="600px"
        footer={false}
        onCancel={handleCancelModal}
        onOk={() => {}}
      >
        <Signup
          handleCancelModal={handleCancelModal}
          form={form}
          brokerageInput={brokerageInput}
          setBrokerageInput={setBrokerageInput}
          currentSelectedFranchise={currentSelectedFranchise}
          setCurrentSelectedFranchise={setCurrentSelectedFranchise}
        />
      </Modal>

      <Modal
        title={
          roleModalFor.current === "ROLE" ? "Select Role" : "Select Admin Role"
        }
        visible={isRoleModalOpen}
        onOk={handleRoleOk}
        onCancel={handleRoleCancel}
      >
        {roleModalFor.current === "ROLE" ? (
          <Select
            style={{ minWidth: "100%" }}
            placeholder="Select Role"
            showArrow
            mode="multiple"
            options={roleSelectOption}
            onChange={handleRoleChange}
            value={rolesArray}
          />
        ) : (
          <Select
            style={{ minWidth: "100%" }}
            placeholder="Select admin Role"
            showArrow
            mode="multiple"
            options={adminRoleSelectOptionFun(adminRole)}
            onChange={handleRoleChange}
            value={rolesArray}
          />
        )}
      </Modal>

      {setBrokerageModal && (
        <BrokerageDetails
          brokerageInput={brokerageInput}
          setBrokerageInput={setBrokerageInput}
          officeInput={officeInput}
          setOfficeInput={setOfficeInput}
          currentSelectedFranchise={currentSelectedFranchise}
          setCurrentSelectedFranchise={setCurrentSelectedFranchise}
        />
      )}
      <Modal
        title={
          roleModalFor.current === "ASSOCIATION"
            ? "Select Association"
            : "Select Brokerage"
        }
        visible={openAssociationModal}
        onOk={() => handleOk(roleModalFor.current)}
        onCancel={() => handleModalCancel()}
      >
        {roleModalFor.current === "ASSOCIATION" ? (
          <Select
            style={{ width: "100%", height: "30px" }}
            placeholder="Select Association"
            onFocus={callAssociation}
            onChange={handleOptionChange}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {associationData?.map((element, index) => (
              <Option key={index} value={element.key}>
                {element.label}
              </Option>
            ))}
          </Select>
        ) : roleModalFor.current === "BROKERAGE" ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Input
              placeholder="Select Company Name"
              value={brokerageInput}
              onClick={() => {
                dispatch(adminAction.openBrokerageSelectModal(true));
              }}
            />
            <Input
              placeholder="Select Office Name"
              value={officeInput}
              onClick={() => {
                dispatch(adminAction.openBrokerageSelectModal(true));
              }}
            />
          </div>
        ) : (
          ""
        )}
      </Modal>

      {/* delete subcription Modal */}
      <Modal
        visible={popConfirmVisible.visible}
        width="400px"
        footer={false}
        onCancel={() => {
          setPopConfirmVisible({ visible: false });
          form.resetFields();
        }}
      >
        <Form form={form} layout="vertical" style={{ width: "100%" }}>
          <Form.Item
            name="manualNotes"
            label={
              <span style={{ marginTop: "10px" }}>
                Please provide the reason to remove this plan?
              </span>
            }
            rules={[{ required: true, message: "Please add valid reason!" }]}
            style={{ marginBottom: "20px" }}
          >
            <TextArea
              onChange={(e) => setReason(e.target.value)}
              rows={4}
              placeholder="Enter some text..."
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              htmlType="submit"
              onClick={handleConfirmRemovePlan}
              type="primary"
              style={{ width: "100px" }}
              disabled={reason.trim() === ""}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* subcription Modal */}
      <Modal
        title={
          <span
            style={{ display: "flex", justifyContent: "center", color: "grey" }}
          >
            {showCreateManualSubMoal.activePlan ? "ADD PLAN" : "EDIT PLAN"}
          </span>
        }
        visible={showCreateManualSubMoal.visible}
        onCancel={handleSubcriptionCancel}
        footer={false}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={showCreateManualSubMoal.activePlan ? handleAddSubcription : handleEditSubscription}
          style={{ width: "100%" }}
        >
          {showCreateManualSubMoal.source !== "SUBCTN_DATE" && (
            <Form.Item
              name="activePlan"
              label="Select Plan"
              rules={[
                {
                  required: true,
                  message: "Please Select A Plan!",
                },
              ]}
            >
              <Select placeholder="Please Select a Plan" allowClear>
                <Option value="RESURE_TRIAL">RE-Sure Trial</Option>
                <Option value="RESURE_PLAN">RE-Sure Plan</Option>
              </Select>
            </Form.Item>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="startDate"
              label="Select Start Date"
              rules={[{ required: true, message: "Please select start date!" }]}
            >
              <DatePicker
                format="MM-DD-YYYY"
                onChange={(date) => setStartDate(date)}
                disabledDate={(current) => {
                  return current && current < moment().startOf("day");
                }}
              />
            </Form.Item>
            <Form.Item
              name="endDate"
              label="Select End Date"
              rules={[{ required: true, message: "Please select end date!" }]}
            >
              <DatePicker
                format="MM-DD-YYYY"
                disabledDate={(current) => {
                  return (
                    startDate &&
                    current &&
                    current <= moment(startDate).startOf("day")
                  );
                }}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="manualNotes"
            label="Notes"
            rules={[{ required: true, message: "Please add notes!" }]}
          >
            <TextArea rows={4} placeholder="Enter some text..." />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Send Signup Email */}
      <Modal
        visible={sendSignupEmail}
        footer={false}
        onCancel={handleCancel}
        title={
          <p
            style={{
              textAlign: "center",
              color: "gray",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Add Sign-up Link
          </p>
        }
      >
        {addAssociationLoading ? (
          <div
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            <Form
              layout="vertical"
              onFinish={handleFinish}
              name="1normal_login_page1"
              form={form}
              scrollToFirstError
              style={{ width: "100%" }}
            >
              <Form.Item
                name="association"
                label="Association"
                rules={[
                  {
                    required: true,
                    message: "Please select a association!",
                  },
                ]}
              >
                <div
                  style={{ display: "flex", gap: "10px" }}
                  onClick={handleAssociation}
                >
                  <Input
                    style={{ width: "100%", height: "35px" }}
                    placeholder=" Select Association"
                    value={selectedAssociation?.label}
                    readOnly={true}
                  />
                  <SearchOutlined
                    style={{
                      fontSize: "18px",
                      marginTop: "7px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>

      {/* Association Data */}
      <Modal
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancelAssociationData}
        title={
          <p
            style={{
              textAlign: "center",
              color: "gray",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Select Association
          </p>
        }
      >
        {associationDataLoading ? (
          <div style={{ minHeight: "60vh" }}>
            <Spin size="large" style={{ margin: "15% 0 0 50%" }} />
          </div>
        ) : (
          <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            {associationDataError ? (
              message.error(associationDataError?.message)
            ) : (
              <>
                {associationData && associationData?.length > 0 ? (
                  <List
                    itemLayout="horizontal"
                    dataSource={associationData}
                    renderItem={(item, idx) => (
                      <List.Item
                        style={{
                          cursor: "pointer",
                        }}
                        key={item.key}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.background = "#92b3d1";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.background = "white";
                        }}
                        onClick={() => {
                          setSelectedAssociation(item);
                          setIsModalVisible(false);
                        }}
                      >
                        <List.Item.Meta
                          title={
                            <span
                              style={{
                                padding: "0 10px",
                                color: "black",
                                fontWeight: "normal",
                              }}
                            >
                              {item?.label}
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                ) : (
                  <Empty />
                )}
              </>
            )}
          </div>
        )}
      </Modal>

      {/* View Sign-up Links */}
      <Modal
        visible={viewSignupLink}
        footer={false}
        width={630}
        onCancel={handleCancelViewSignupLink}
        title={
          <p
            style={{
              textAlign: "center",
              color: "gray",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Select Association
          </p>
        }
      >
        {viewSignupLinkLoading ? (
          <div style={{ minHeight: "60vh" }}>
            <Spin size="large" style={{ margin: "15% 0 0 50%" }} />
          </div>
        ) : (
          <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
            {viewSignupLinkDataError ? (
              message.error(associationDataError?.message)
            ) : (
              <>
                {viewSignupLinkData?.map((data, index) => (
                  <div key={data?._id} style={{ width: "550px" }}>
                    <h3>{`${index + 1}. ${data?.label}`}</h3>

                    {data?.redirect?.map((redirectObj, redirectIndex) => (
                      <div
                        style={{
                          width: "550px",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          borderRadius: "5px",
                          padding: "8px",
                          margin: "0.5rem 0 0 0",
                        }}
                      >
                        <div style={{ width: "400px" }}>
                          <ul style={{ margin: 0, padding: 0 }}>
                            <li key={redirectIndex}>
                              <Typography.Text
                                style={{ color: "#033566" }}
                                copyable
                                ellipsis
                              >
                                {redirectObj.link}
                              </Typography.Text>
                            </li>
                          </ul>
                        </div>
                        <div style={{ width: "70px" }}>
                          <Switch
                            key={redirectIndex}
                            checkedChildren={"Active"}
                            unCheckedChildren={"Inactive"}
                            defaultChecked={redirectObj.isActive}
                            onChange={(value) =>
                              handleToggle({
                                value,
                                associationId: data._id,
                                redirectSignupLinkId: redirectObj._id,
                              })
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                {associationData?.length === 0 && <Empty />}
              </>
            )}
          </div>
        )}
      </Modal>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0px 30px 10px 10px",
          position: "absolute",
          top: "12px",
          left: "45%",
        }}
      >
        <div></div>
        <div>
          <h1
            style={{
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            Users List
          </h1>
        </div>
        <Tooltip
          title={"Send Signup Email"}
          placement="right"
          color={"#085191"}
        ></Tooltip>
        {roleAccessed &&
        adminRole &&
        roleAccessFunc(roleAccessed, "CREATE_USER") ? (
          <Dropdown trigger={["click", "hover"]} overlay={<MenuItem />}>
            <MoreOutlined
              style={{
                fontSize: "25px",
                fill: "#085191",
                color: "#085191",
                marginLeft: "30px",
                marginTop: "-5px",
              }}
            />
          </Dropdown>
        ) : null}
      </div>

      <div
        style={{
          position: "absolute",
          top: "65px",
          zIndex: "111",
          left: "210px",
        }}
      >
        <Button
          disabled={disableBtn}
          onClick={() => {
            hadndleResetAll();
            setdisableBtn(true);
          }}
          size="small"
          style={{ marginLeft: "10px" }}
        >
          Reset Filters
        </Button>
      </div>
      <Table
        bordered
        loading={personLoading}
        className="components-table-demo-nested"
        columns={columns}
        onChange={handleChange}
        // expandable={{ expandedRowRender }}
        dataSource={personData || []}
        scroll={{ x: "max-content", y: "70vh" }}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: false,
          pageSize: 30,
          current: currentPage,
          total: totalItems,
          onChange: (page) => {
            if (!searchRef.current) {
                let queryData = {
                    ...query,
                    page,
                    limit:30
                  }
              dispatch(
                cmsDataHandle.getPersonsData(queryData),
              );
            setCurrentPage(page);
            };
          },
        }}
      />
    </>
  );
}

export { TablbeView };
