import { message } from "antd";
import { officeTypeApi } from "../utils/api";
import { officeTypeConstants } from "./action-types";
import { cmsDataHandle } from "../../CMS/state/actions";
import { subscriptionAction } from "../../SubscriptionVerication/state/action";

const getOfficeTypes = (source,query) => {
    return (dispatch, getState) => {
      dispatch(getOfficeTypeLoading());
      officeTypeApi
        .getOfficeTypes(source,query)
        .then((response) => {
       dispatch(getOfficeTypeSuccess(response));
        })
        .catch((error) => {
          if (error.response) {
            dispatch(getOfficeTypeError(error.response.data));
          }
        });
    };
  };


const getOfficeTypeLoading = (response) => {
  return {
    type: officeTypeConstants.OFFICE_TYPE_LOADING,
    officeLoading: true,
    officeError: false,
  };
};

const getOfficeTypeSuccess = (response) => {
  return {
    type: officeTypeConstants.OFFICE_TYPE_DATA,
    officeLoading: false,
    officeError: false,
    officeData: response.data.info.data,
    totalRecords:response.data.info.totalRecords
  };
};

const getOfficeTypeError = (response) => {
  return {
    type: officeTypeConstants.OFFICE_TYPE_ERROR,
    officeLoading: false,
    officeError: true,
  };
};

const updateOfficeType = (officeId, isVerified,page) => {
  let query={
    page:page,
    limit:10
  }
    return (dispatch, getState) => {
        dispatch(getOfficeTypeLoading());
        officeTypeApi
          .updateOfficeType(officeId, isVerified, "verify")
          .then((response) => {
            message.success("Updated Successfully")
            dispatch(getOfficeTypes('verifyCreatedFirm', query));
          })
          .catch((error) => {
            if (error.response) {
              message.error(error?.response?.data?.message || "We encountered an issue while updating the office type.Please try again later!")
              dispatch(getOfficeTypes('verifyCreatedFirm', query));
            }
          });
        };
}

const getAllPersons = (query) => {
  return (dispatch, getState) => {
    dispatch(getPersonsLoading());
    officeTypeApi
      .getAllPersonsApi(query)
      .then((response) => {
        dispatch(getPersonsSuccess(response));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getPersonsError(error.response.data));
        }
        dispatch(getPersonsError());
      });
  };
};

const getPersonsSuccess = (response) => {
  return {
    type: officeTypeConstants.PERSON_DATA,
    personsData: response?.data?.info?.data,
    totalRecords: response?.data?.info?.totalRecords
    };
};

const getPersonsError = (response) => {
  return {
    type: officeTypeConstants.PERSON_ERROR,
    personsLoading: false,
    personsError: true,
  };
};

const getPersonsLoading = (response) => {
  return {
    type: officeTypeConstants.PERSON_LOADING,
    personsLoading: true,
    personsError: false,
  };
};

const checkIsAdminRoleUpdated = (payload) => {
  return {
    type: officeTypeConstants.SET_ADMIN_ROLE_UPDATED,
    isAdminRoleUpdated: payload,
  }
}

const updatePerson = (payload, source, query) => {
  return (dispatch, getState) => {
    dispatch(getPersonsLoading());
    officeTypeApi
      .updatePerson(payload)
      .then((response) => {
        if (source === "ADMIN_ROLE_UPDATE") {
          dispatch(cmsDataHandle.getPersonsData(query));
          dispatch(checkIsAdminRoleUpdated([]));
        } else if(source === "SUBSCRIPTION") {
          dispatch(subscriptionAction.getAllPersonsforSubscription(query));
        } else {
          dispatch(getAllPersons());
        }
        message.success("Updated Successfully");
      })
      .catch((error) => {
        if (error.response) {
          message.error(error?.response?.data?.message || "We encountered an issue while updating person data.Please try again later!");
        }
        dispatch(getPersonsError());
      });
  };
};

const getFirmsUpdateLoading = (response) => {
  return {
    type: officeTypeConstants.FIRM_UPDATE_LOADING,
  };
};

const getFirmsUpdateError = (response) => {
  return {
    type: officeTypeConstants.FIRM_UPDATE_ERROR,
  };
};

const getFirmsUpdateData = (response) => {
  return {
    type: officeTypeConstants.FIRM_UPDATE_DATA,
    firmUpdateData: response.data.info.data,
    totalRecords:response.data.info.totalRecords
  };
}

const getUpdatedFirms = (query) => {
  return (dispatch, getState) => {
    dispatch(getFirmsUpdateLoading());
    officeTypeApi
      .getOfficeTypes("",query)
      .then((response) => {

        dispatch(getFirmsUpdateData(response));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getFirmsUpdateError(error.response.data));
        }
      });
  };
};

const updatedFirmVerify = (officeId, isVerified,data) => {
   return (dispatch, getState) => {
      dispatch(getFirmsUpdateLoading());
       officeTypeApi
        .updateOfficeType(officeId, isVerified, "updateVerify")
        .then((response) => {
          message.success("Updated Successfully")
          dispatch(getUpdatedFirms());
        })
        .catch((error) => {
          message.error(error?.response?.data?.message || "We encountered an issue while verifying the firm.Please try again later!");
          if (error.response) {
            dispatch(getFirmsUpdateError(error.response.data));
            dispatch(getUpdatedFirms());
          }
        });
      };
}

  //filter active/deactived
  const getFilterStatusLoading = () => {
    return {
      type: officeTypeConstants.FIRM_FILTER_STATUS_LOADING,
      personLoading:true,
    };
  };
  const getFilterStatusError = (error) => {
    return {
      type: officeTypeConstants.FIRM_FILTER_STATUS_ERROR,
      personError: error,
      personLoading: false,
    };
  };
  const getFilterStatusSuccess = (response) => {
    return {
      type: officeTypeConstants.FIRM_FILTER_STATUS_SUCESS,
      personsData: response.data.info.data,
      totalItems: response.data.info.totalRecords,
      personLoading: false,
    };
  };
  const getFilterStatus = (query,onlyOffices) => {
    return (dispatch) => {
      dispatch(getFilterStatusLoading());
      officeTypeApi
        .FirmFilterRequest(query,onlyOffices)
        .then((response) => {
          dispatch(getFilterStatusSuccess(response));
        })
        .catch((error) => {
          dispatch(getFilterStatusError(error));
        });
    };
  };


export const officeTypeHandle = {
  getOfficeTypes,
  updateOfficeType,
  getAllPersons,
  updatePerson,
  getUpdatedFirms,
  updatedFirmVerify,
  getFilterStatus,
};
