import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const RecipientList = ({ data, type }) => (
  <div style={{ color: "#575454", fontSize: "18px", lineHeight: "1.6",marginTop:data?.length>0?"-20px":"0px" }}>
    <div>
    
      {data?.map((el, index) => (
        <div
          key={index}
          style={{
            color: "grey",
            padding: "5px 0",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: "18px", marginRight: "8px" }}>•</span>
          <div
            style={{
              display: "flex",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span style={{ color: "black", marginRight: "8px" }}>{el?.fullName}</span>
            <Text
              style={{
                color: "blue",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textDecoration:"underline"
              }}
              // copyable={{ text: el?.email }}
            >
              - {el?.email}
            </Text>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default RecipientList;
