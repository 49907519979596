/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import { Tooltip, message, Button } from "antd";
import { BiShare } from "react-icons/bi";
import { RiShareForwardLine } from "react-icons/ri";
import newStyles from "../style/RtdRow.module.css";
import { Modal, Spin } from "antd";
import moment from "moment";
import { commonDataHandle } from "../../Common/state/actions.js";
import { useHistory } from "react-router-dom";
import { FilePdfOutlined, CheckCircleFilled, MessageTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import { SignableAndReadOnly } from "../../Common/components/SignableAndReadOnly";
import { useMediaQuery } from "react-responsive";
import { offerTableHandle } from "../../OfferTable/state/actions";
import { createOfferContractTypes } from "../../Common/commondata/commonData.js";


const SignalIcon = (props) => {
  return (
    <div className={newStyles.signalIconOutline}>
      <div
        className={newStyles.signalIndicator}
        style={{
          backgroundColor: props.color === "SUBMITTED" ? "green" : props.color === "DRAFT" ? "yellow" : props.color === "CANCEL" ? "red" : props.color === "INVALIDATED" ? "darkgray" : "white",
        }}
      />
    </div>
  );
};

const SellerComp = (props) => {
  let timestamp = props && props.sellerDocumentUpdatedAt;
  let date = timestamp && new Date(timestamp);
  const dateNew = timestamp && moment(date).format("MM/DD/YYYY");
  const time = timestamp && moment(date).format("h:mm a");
  return (
    <div className={newStyles.sellerCompContainer}>
      <div className={newStyles.sellerCenterline}>
        <>
          <div
            style={{
              width: "100%",
              position: "relative",
              borderStyle: props.RTDInitiator === "SELLER" ? "solid" : "none",
              height: 0,
              borderColor: props?.RTDInitiator === "SELLER" ? (props?.invalidated ? "darkgray" : "#2976be") : "white",
            }}
          >
            {props?.RTDInitiator === "SELLER" &&
              props?.client === "SELLER" &&
              props?.sellerDocumentStatus === "SUBMITTED" &&
              (props?.checked?.showBuyer ? (
                <span style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
                  <Tooltip title="Document sent to Buyer Agent">
                    <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: props?.invalidated ? "darkgray" : "green" }} />
                  </Tooltip>
                </span>
              ) : (
                <></>
              ))}

            <div className={newStyles.dateAndTimeSeller}>
              <div>{props.sellerDocumentUpdatedAt === undefined || props.contractType === "TO" ? "" : dateNew}</div>
              <div>{props.sellerDocumentUpdatedAt === undefined || props.contractType === "TO" ? "" : time}</div>
            </div>
          </div>
          {((props.contractType === "INSP_OBJ" || props.contractType === "AVO" || props.contractType === "TRON" || props.contractType === "NTT") && props.RTDInitiator === "BUYER") || props.contractType === "TO" ? null : <SignalIcon color={props.invalidated ? "INVALIDATED" : props.sellerDocumentStatus} />}
        </>
      </div>
    </div>
  );
};

const BuyerComp = (props) => {
  let timestamp= props?.ispdfClone && props?.contractType==="CBSR"? props?.createdDateMili :  props?.buyerDocumentUpdatedAt;
  let date = timestamp && new Date(timestamp);
  const dateNew = timestamp && moment(date).format("MM/DD/YYYY");
  const time = timestamp && moment(date).format("h:mm a");

  return (
    <div className={newStyles.buyerCompContainer}>
      <div className={newStyles.buyerCenterLine}>
        <>
          {(props.contractType === "TO" || props.contractType === "LBPOS" || props.contractType === "NTT") && props.RTDInitiator === "SELLER" ? null : <SignalIcon color={props.invalidated ? "INVALIDATED" : props.buyerDocumentStatus} />}
          <div
            style={{
              width: "100%",
              position: "relative",
              borderStyle: props.RTDInitiator === "BUYER" ? "solid" : "none",
              height: 0,
              borderColor: props.RTDInitiator === "BUYER" ? (props?.invalidated ? "darkgray" : "#ffce51") : "white",
            }}
          >
            {props.RTDInitiator === "BUYER" &&
              props.client === "BUYER" &&
              props.buyerDocumentStatus === "SUBMITTED" &&
              (props.checked.showSeller ? (
                <span style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
                  <Tooltip title="Document sent to Seller Agent">
                    <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: props.invalidated ? "darkgray" : "green" }} />
                  </Tooltip>
                </span>
              ) : (
                <></>
              ))}

            <div className={newStyles.dateAndTimeBuyer}>
              <div>
                {" "}
                <Tooltip title={props.ispdfClone && props.contractType === "CBSR" ? "Offer Imported Date" : null} placement="top">
                  {dateNew && props.contractType !== "TO" ? dateNew : ""}
                </Tooltip>
              </div>
              <div>
                {" "}
                <Tooltip title={props.ispdfClone && props.contractType === "CBSR" ? "Offer Imported Date" : null} placement="top">
                  {time && props.contractType !== "TO" ? time : ""}
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

const DetailComp = ({ doc, seller, role, selectedData, ispdfClone, userAuthKey, handleCheckboxChangeBuyer, isSmallScreen, isMediumScreen, allBuyerAgentData }) => {

  let dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const { URLofferTableJSON, currentSellerAgentData, offerTableAuthKey } = useSelector((state) => state.offerTable);
  let sellerOpenOfferTable = URLofferTableJSON?.urlData?.sellerOpenOfferTable;

  const urlShortningForDraft = () => {
    if (seller?.ispdfClone || seller?.isPdf) {
      let fileUrl = seller?.resureSignedPdfLink ? seller?.resureSignedPdfLink : seller?.pdfLink;
      window.open(fileUrl, "_blank").focus();
    } else {
      setLoader(true);
      let buildData = {
        builtForId: URLofferTableJSON?.builtForId ? URLofferTableJSON?.builtForId : currentSellerAgentData?._id,
        key: URLofferTableJSON?.builtForId?URLofferTableJSON?.builtForId:currentSellerAgentData?._id,
        builtForSection: "DOCUMENT",
        signable: sellerOpenOfferTable ? false : true,
        openFor: sellerOpenOfferTable ? "SELLER" : "SELLER_AGENT",
        contractType: seller?.contractType,
        propertyId: URLofferTableJSON?.propertyId,
        ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
        documentId: seller?.documentId,
        contractId: seller?.contractId,
        token: userAuthKey,
      };
      dispatch(transactionLaneDataHandle.getDraftDocumentData(buildData, null, history));
    }
  };

  function isSingleWord(text) {
    return text?.trim()?.split(/\s+/)?.length === 1;
  }





  return (
    <div className={newStyles.documentDetailOuterCOntainer}>
      <div
        className={newStyles.documentBox}
        style={{
          borderColor: seller?.invalidated ? "darkgray" : seller?.RTDInitiator === "SELLER" ? "#3376a8" : "#ffce51",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
            width: "15%",
            // color: sellerOpenOfferTable ?"darkgray":"rgb(41,118,190)",
            pointerEvents: sellerOpenOfferTable ? "none" : "",
          }}
        >
          {/* {seller?.invalidated || sellerOpenOfferTable ? (
            ""
          ) : (
            <span>
              <BiShare size={isSmallScreen ? 14 : isMediumScreen ? 16 : 20} style={{ color: "#376ada" }} onClick={() => setIsEmailModalVisible({...isEmailModalVisible,isEmailModalOpen:true,sendMultipleDoc:true})} />
            </span>
          )} */}
        
        </div>

        <div className={newStyles.documentNameContainer}>
          <div
            onClick={() => {
              urlShortningForDraft();
            }}
            className={`${newStyles.documentName} ${isSingleWord(doc) ? newStyles.singleWord : ""}`}
          >
            <span style={{ ...(seller?.invalidated ? { textDecoration: "line-through" } : {}) }}>{doc}</span> {ispdfClone ? seller?.contractType === "CBSR" ? <FilePdfOutlined /> : "" : (seller?.isPdf && <FilePdfOutlined />)}
          </div>
          {seller?.contractType !== "CBSR" &&
            (seller?.invalidated ? (
              <Tooltip title={<span style={{ fontSize: isSmallScreen ? 10 : isMediumScreen ? 12 : 14 }}>Invalidated</span>}>
                <ExclamationCircleOutlined style={{ color: "red" }} />
              </Tooltip>
            ) : (
              ""
            ))}
        </div>

        <div
          style={{
            color: "darkgray",
            textAlign: "center",
            cursor: "pointer",
            width: "15%",
          }}
        >
          {/* <RiShareForwardLine
            size={window.screen.width > 850 ? 30 : 15}
          /> */}
          {/* <span>
            {seller?.RTDInitiator === "SELLER" &&
             seller?.client === "SELLER" &&
             (!sellerOpenOfferTable ?
              <Tooltip title={seller?.invalidated ? null : <span style={{ fontSize: isSmallScreen ? 10 : isMediumScreen ? 12 : 14 }}>Send Document to Buyer Agent</span>}>{seller?.invalidated ? <RiShareForwardLine size={isSmallScreen ? 14 : isMediumScreen ? 16 : 20} style={{ cursor: "context-menu" }} /> : <RiShareForwardLine style={{ color: "#376ada" }} size={isSmallScreen ? 14 : isMediumScreen ? 16 : 20} onClick={() => handleCheckboxChangeBuyer(seller?.contractType, seller?.RTDInitiator)} />}</Tooltip> : <></>)}</span> */}
        </div>
      </div>
    </div>
  );
};

function RtdRow(props) {
 

  const { contractType, ispdfClone, documentId, selectedData, client, RTDInitiater,   } = props;
  const { updateDocumentLoading } = useSelector((state) => state.rtdTransactionLane);
  const { URLofferTableJSON, offerTableAuthKey, offerTablePropertyId} = useSelector((state) => state.offerTable);

  let sellerOpenOfferTable = URLofferTableJSON?.urlData?.sellerOpenOfferTable;
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery({ maxWidth: 550 });
  const isMediumScreen = useMediaQuery({ minWidth: 551, maxWidth: 850 });
  const [showCoModal, setShowCoModal] = useState(false);
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isEmailModalVisible: false
  });

  const checked = {
    showBuyer: props.showBuyer,
    showSeller: props.showSeller,
  };
  
  const handleCheckboxChangeBuyer = (contractType, RTDInitiater, sellerAgentSigned) => {
    // console.log("selectedData", props?.selectedData?.sellerAgentSigned, props?.selectedData);
      if (props?.selectedData?.counterProposalExist && !sellerAgentSigned) {
        if (props.selectedData.ispdfClone && !props?.selectedData?.sellerAgentSigned) {
          setShowCoModal(true);
          return;
        }
      }

      if (!nestedModalOpen.isEmailModalVisible) {
        setNestedModalOpen({
          ...nestedModalOpen,
          isEmailModalVisible: true,
          isAgent:true,
        });
        dispatch(commonDataHandle.getEmailSignData(offerTableAuthKey));
      }
  };

  const handleCoConfirmCancel = () => {
    setShowCoModal(false);
  };
  const handleAlreadySignedSend = () => {
    setShowCoModal(false);
    handleCheckboxChangeBuyer(contractType, "SELLER", true);
  };

  const handleSignOffer = () => {
    setShowCoModal(false);
    dispatch(offerTableHandle.acceptOfferViaResureSignFun({ offerTableAuthKey, offerTablePropertyId, documentId: props?.selectedData?.CBSRId, onlySellerAgentSignRequired: true }));
  };
  return (
    <>
      {updateDocumentLoading && (
        <div style={{ position: "fixed", left: "38%", top: "50%", zIndex: "10" }}>
          <Spin size="large" />
        </div>
      )}
      <>
        <Modal width={800} title={<div style={{ color: "#1890ff", fontSize: "18px", fontWeight: "bold", marginBottom: "5px", textAlign: "center", textDecoration: "underline" }}>Seller Agent Signature Required</div>} size="100px" visible={showCoModal} onCancel={handleCoConfirmCancel} footer={null}>
          <div className={newStyles.signOfferConfirmationMsgDiv}>
            <div>
              <MessageTwoTone style={{ fontSize: "24px" }} />
            </div>
            <div style={{ fontSize: "16px", color: "#777" }}>
            Seller agent should sign the broker disclosure section of the Contract to Buy and Sell Real Estate before the Counterproposal is sent to the buyer's agent.
            </div>
          </div>
          <div className={newStyles.signOfferConfirmationBtns}>
            <Button onClick={handleSignOffer}>Sign Disclosure via RE-Sure Sign</Button>
            <Button type="primary" onClick={handleAlreadySignedSend}>
            I have signed the disclosure, Send
            </Button>
          </div>
        </Modal>


        {contractType !== "TR" ? (
          <div className={newStyles.eachDocumentRowContainer}>
          {(nestedModalOpen.isEmailModalVisible) &&
          (<SignableAndReadOnly
           role={props?.tags}
           source={"SELLING_RTD"}
           selectedData={selectedData}
           documentId={documentId}
           isEmailModalVisible={nestedModalOpen.isEmailModalVisible}
           setIsEmailModalVisible={setNestedModalOpen}
           ispdfClone={ispdfClone}
           nestedModalOpen={nestedModalOpen}
           client={client}
           userAuthKey={offerTableAuthKey}
           RTDInitiater={RTDInitiater}
           contractType={contractType}
           allBuyerAgentData={props?.allBuyerAgentData}
            />)}
            <SellerComp {...props} sellerOpenOfferTable={sellerOpenOfferTable} checked={checked} handleCheckboxChangeBuyer={handleCheckboxChangeBuyer} />

            <DetailComp sellerOpenOfferTable={sellerOpenOfferTable} seller={props} doc={props.contractName} role={props.tags} selectedData={props.selectedData} ispdfClone={props.ispdfClone} userAuthKey={props.userAuthKey} handleCheckboxChangeBuyer={handleCheckboxChangeBuyer} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} allBuyerAgentData={props?.allBuyerAgentData} />

            <BuyerComp sellerOpenOfferTable={sellerOpenOfferTable} {...props} checked={checked} handleCheckboxChangeBuyer={handleCheckboxChangeBuyer} />
          </div>
        ) : null}
      </>
    </>
  );
}

export default RtdRow;
