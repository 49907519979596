const buildSignatureBlocks = (signers, type, rootDocData, requiredOpenFor, contractData) => {
  if (!Array.isArray(signers)) {
    console.log("Unable to build signature block for", signers);
    return "";
  }
  let signerType;
  if (type === "Buyers") {
    signerType = "Buyer";
  } else if (type === "Sellers") {
    signerType = "Seller";
    if (rootDocData?.contractType === "ERTL") signerType = "Landlord";
    if (rootDocData?.contractType === "ETCT") signerType = "Tenant";
  } else {
    signerType = type;
  }
  let brokerFields = {};
  if (type === "BUYER_BROKER" || type === "BUYER_WITHDRAW") {
    brokerFields = contractData?.buyerBrokerFields || {};
  } else if (type === "SELLER_BROKER") {
    brokerFields = contractData?.sellerBrokerFields || {};
  }
  const isDWRAndSeller = rootDocData?.contractType === "DWR" && signerType === "Seller";
  const signerTitleCriteria = signerType === "Authorized" && rootDocData?.contractType === "CI";

  let html = "";

  signers.forEach((element) => {
    if (element.isCorp) {
      element.signers.forEach((signer) => {
        const isActiveSigner = signer.personId === rootDocData?.builtForId && element.key === rootDocData?.key && rootDocData?.openFor === requiredOpenFor && rootDocData?.signable && !rootDocData?.checkForDisable?.disabled;

        const signerStatus = isActiveSigner ? "active-signer clickable" : "inactive-signer";
        const signerId = `${requiredOpenFor}_${signer.personId}_key_${element.key}`;

        html += `
          <div class="signature-block">
            <table class="mt">
              <thead></thead>
              <tbody>
                <tr>
                  <td style="width: 75%;" class="border-bottom-input" id="${signerId}">
                    <div class="signature-block ${signerStatus}" data-signerId="${signer.personId}" data-signerKey="${element.key}"></div>
                  </td>
                  <td style="width: 25%; vertical-align: bottom;" class="border-bottom-input ua tb" data-signatureDate="${signerId}"></td>
                </tr>
                <tr>
                  <td>${isDWRAndSeller ? "Signature: " : `${signerType}'s Signature: `}<span class="ua tb">${element.fullName || ""}</span><br><span class="ua tb" data-signerFullName="${signerId}">${signer.fullName || ""}</span>${signer.title ? `, <span class="ua tb">${signer.title}</span>` : ""}</td>
                  <td>Date</td>
                </tr>
              </tbody>
            </table>
          </div>`;
      });
    } else {
      const uuidKey = element.key || null;
      const personId = element.personId || element._id;
      const isActiveSigner = rootDocData?.openFor === requiredOpenFor && !rootDocData?.checkForDisable?.disabled && ((personId === rootDocData?.builtForId && personId === rootDocData?.key) || requiredOpenFor === "TITLE_COMPANY") && rootDocData?.signable;
      const signerStatus = isActiveSigner ? "active-signer clickable" : "inactive-signer";
      const signerId = `${requiredOpenFor}_${personId || requiredOpenFor}_key_${uuidKey}`;
      const signatureDateId = `${requiredOpenFor}_${personId || element._id || requiredOpenFor}_key_${uuidKey}`;
      const widthStyle = signerTitleCriteria ? "width: 55%;" : "width: 80%;";
      const signerTypeLabel = brokerFields.signatureLabel ? brokerFields.signatureLabel.trim() + " " : ["BUYER_BROKER", "SELLER_BROKER"].includes(type) ? "Broker's Name: " : isDWRAndSeller ? "Signature: " : `${signerType}${signerType === "Authorized" ? " Signer: " : `'s Signature: `}`;
      html += `
      <div class="signature-block">
        <table class="mt">
          <thead></thead>
          <tbody>
            <tr>
              <td style="${widthStyle}" class="border-bottom-input" id="${signerId}">
                <div class="signature-block ${signerStatus}" data-signerId="${personId}" data-signerKey="${uuidKey}"></div>
              </td>
              ${signerTitleCriteria ? '<td style="width: 25%; vertical-align: bottom;" class="border-bottom-input"><input id="SignerTitleField" type="text" name="SignerTitleInput" style="border-bottom: none !important;"/></td>' : ""}
              <td style="width: 25%; vertical-align: bottom;" class="border-bottom-input ua tb" data-signatureDate="${signatureDateId}"></td>
            </tr>
            <tr>
              <td>${signerTypeLabel}<span class="ua tb" data-signerFullName="${signerId}">${element.fullName || ""}</span></td>
              ${signerTitleCriteria ? "<td>Title</td>" : ""}
              <td>Date</td>
            </tr>
          </tbody>
        </table>
      </div>`;
    }
  });

  return html;
};

export default buildSignatureBlocks;
