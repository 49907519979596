import React, { useState } from "react";
import { Modal, Button } from "antd";
import styles from "../../OfferTable/styles/OfferTable.module.css";

const LicenseDetails = ({ record }) => {
  const [licenseState, setLicenseState] = useState({
    licenseModalVisible: false,
    selectedRecord: null,
  });

  const showLicenseModal = (record) => {
    setLicenseState({
      licenseModalVisible: true,
      selectedRecord: record || {},
    });
  };

  const handleCancel = () => {
    setLicenseState({
      licenseModalVisible: false,
      selectedRecord: null,
    });
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", textAlign: "left", maxHeight: "300px", overflow: "hidden", overflowY: "auto", padding: "5px" }}>
        {record?.licenseData?.map((el, index) =>
          el.idType === "COLORADO STATE LICENSE" ? (
            <div key={index} style={{ display: "flex", flexDirection: "column", marginBottom: "10px", padding: "10px" }}>
              <span>
                <strong>Id Type:</strong> {el.idType}
              </span>
              <span>
                <strong>ID No:</strong> {el?.id?.toUpperCase()}
              </span>
              {!isNaN(Date.parse(el.expiryDate)) && (
                <span>
                  <strong>Expire Date:</strong> <span style={{ color: el.licenseStatus === "INVALID" ? "#DC143C" : el.licenseStatus === "VALID" ? "green" : "black", fontSize: "12px", fontWeight: "bolder" }}> {el.expiryDate}</span>
                </span>
              )}
              <span>
                <strong>Status:</strong> <span style={{ color: el.licenseStatus === "INVALID" ? "#DC143C" : el.licenseStatus === "VALID" ? "green" : "black", fontSize: "12px", fontWeight: "bolder" }}> {el.licenseStatus}</span>
              </span>
              <span>
                <strong>Notes:</strong> {el.notes || ""}
              </span>
            </div>
          ) : null
        )}
      </div>
      {record?.licenseData?.length ? (
        <Button type="link" onClick={() => showLicenseModal(record)}>
          View All Licenses
        </Button>
      ) : (
        "N/A"
      )}
      <Modal width={700} style={{ height: "600px" }} title={<div style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold", color: "grey" }}> License Details</div>} visible={licenseState.licenseModalVisible} onCancel={handleCancel} footer={null}>
        <div style={{ width: "100%", height: "400px", overflow: "auto" }}>
          <table className={styles.licenseTable} style={{ width: "100%" }}>
            <thead>
              <tr style={{ fontSize: "14px", height: "15px", borderRadius: "3px" }}>
                <th style={{ width: "15%" }}>ID Type</th>
                <th style={{ width: "15%" }}>ID No.</th>
                <th style={{ width: "15%" }}>Expire Date</th>
                <th style={{ width: "30%" }}>Notes</th>
              </tr>
            </thead>
            <tbody style={{ border: "none" }}>
              {licenseState.selectedRecord?.licenseData?.map((el) => (
                <tr key={el._id} style={{ fontSize: "14px", height: "40px" }}>
                  <td style={{ fontWeight: "bold", fontSize: "12px" }}>{el?.idType}</td>
                  <td style={{ color: "#178DFA", fontWeight: "bolder", textAlign: "center", fontSize: "12px" }}>
                    {el?.id.toUpperCase()}{" "}
                    {el?.licenseStatus && (
                      <p>
                        {" "}
                        <span style={{ color: "black" }}>Status:</span> <span style={{ color: el.licenseStatus === "INVALID" ? "#DC143C" : el.licenseStatus === "VALID" ? "green" : "black", textAlign: "center", fontSize: "12px", fontWeight: "bolder" }}> {el?.licenseStatus}</span>
                      </p>
                    )}
                  </td>
                  <td style={{ fontWeight: "bolder", fontSize: "12px", textAlign: "center", color: "black" }}>{!isNaN(Date.parse(el.expiryDate)) && el?.expiryDate}</td>

                  <td style={{ textAlign: "center", fontSize: "12px" }}>{el?.notes || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default LicenseDetails;
