/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row, Input, Button, message, Spin } from "antd";
import styles from "../styles/CobuyerCosellerList.module.css";
import buildSignatureBlocksForAgents from "../features/helpers/buildSignatureBlocksForAgents";
import { useDispatch, useSelector } from "react-redux";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { contractDataHandle } from "../state/actions";
import PhoneInput from "react-phone-input-2";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import TextArea from "antd/lib/input/TextArea";

const EditAgentsModal = ({ modalState, setModalState, rootDocData, contractData }) => {
  const { currentUrlId, contractAuthKey, agentDetailsData, agentDetailsLoading } = useSelector((state) => state.createOffer);
  const [emailValue, setEmailValue] = useState("");

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { editAgentsModal, agentType, indidualDetailsEdit, personId, uuidKey } = modalState || {};

  const handleEditAgentsModalCancel = () => {
    dispatch(contractDataHandle.setAgentDetailsData({}));
    form2.resetFields();
    setModalState((prevState) => ({ ...prevState, agentType: "", editAgentsModal: false, personId: "", uuidKey: null, indidualDetailsEdit: false }));
  };

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    if (agentType && indidualDetailsEdit) {
      let newData = localData[agentType]?.filter((el) =>(el.personId?.length === 24 &&  el.personId === personId) || el.key === uuidKey);
      if (newData && newData[0]) {
        dispatch(contractDataHandle.setAgentDetailsData(newData[0]));
      }
    }
  }, [agentType, indidualDetailsEdit, personId, uuidKey, currentUrlId]);

  useEffect(() => {
    if (agentDetailsData && Object.keys(agentDetailsData).length) {
      form2.setFieldsValue({
        ...agentDetailsData,
      });
    }
  }, [agentDetailsData, form2]);

  const handleEmailSearchButtonClick = (values) => {
    let query = {
      email: values?.agentEmail,
    };
    dispatch(contractDataHandle.getAgentDetails({ query, accessToken: contractAuthKey }));
    form1.resetFields();
    setEmailValue("");
  };

  const handleAddOrUpdate = () => {
    form2
      .validateFields()
      .then((values) => {
        values = {
          ...values,
          personId: agentDetailsData?.personId || personId,
        };
        let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
        let clients = localData[agentType] || [];
        if (indidualDetailsEdit) {
          localData[agentType] = clients.map((client) => ((client?.personId?.length === 24 && personId?.length === 24 && client.personId === personId) || client.key === uuidKey ? { ...client, ...values } : client));
        } else {
          if (clients.some((el) => el?.personId?.length === 24 && el.personId === values.personId)) {
            message.error(`Broker already exists.`);
            return;
          }
          localData[agentType] = [...clients, values];
        }
        const sigKey = agentType === "BuyerAgents" ? "CO_BUYER_BROKER" : "CO_SELLER_BROKER";
        const sigBlock = buildSignatureBlocksForAgents({ signers: localData[agentType], type: sigKey, contractData, rootDocData });
        document.querySelectorAll(`[data-signature-block="${sigKey}"]`).forEach((element) => {
          element.innerHTML = sigBlock;
        });

        localStorage.setItem(currentUrlId, JSON.stringify(localData));
        message.success(`Broker ${indidualDetailsEdit ? "Updated" : "Added"} Successfully!`);

        if (contractAuthKey) {
          dispatch(contractStoreHandle.autoSaveContract({currentUrlId, contractAuthKey, valueObj: { [agentType]: localData[agentType] }}));
        }
        handleEditAgentsModalCancel();
      })
      .catch((info) => {});
  };

  return (
    <Modal
      visible={editAgentsModal}
      width={"800px"}
      title={<span style={{ display: "flex", justifyContent: "center", fontSize: "22px", color: "grey" }}>{indidualDetailsEdit ? "EDIT" : "ADD"} BROKER</span>}
      onCancel={handleEditAgentsModalCancel}
      footer={
        agentDetailsData && Object.keys(agentDetailsData).length ? (
          <>
            <Button onClick={handleEditAgentsModalCancel}>Cancel</Button>
            <Button type="primary" onClick={handleAddOrUpdate}>
              {indidualDetailsEdit ? "Update" : "Add"}
            </Button>
          </>
        ) : null
      }
      onOk={handleAddOrUpdate}
    >
      <div style={{ height: "50vh", padding: "20px", overflow: "auto" }}>
        {!indidualDetailsEdit && (
          <Form form={form1} onFinish={handleEmailSearchButtonClick} layout="vertical" autoComplete="off">
            <Col style={{ margin: "0px", padding: "0px", width: "100%", overflowX: "auto" }}>
              <Form.Item
                className={styles.formItem}
                name="agentEmail"
                label={<div style={{ fontSize: "16px", fontWeight: "500", color: "#8e959a" }}>Email</div>}
                rules={[
                  {
                    type: "email",
                    message: "Not a valid Email!",
                  },
                  {
                    required: true,
                    message: "Please Enter Email!",
                  },
                  { whitespace: true },
                ]}
              >
                <div className={styles.inputBoxDiv}>
                  <Input
                    placeholder="Enter Agent email"
                    value={emailValue}
                    onChange={(e) => {
                      setEmailValue(e.target.value);
                    }}
                    onPressEnter={() => form1.submit()}
                  />
                  <Button type="primary" htmlType="submit" style={{ margin: "0px 10px 0px 5px" }} disabled={!emailValue}>
                    Search
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Form>
        )}
        {agentDetailsLoading ? (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "150px" }}>
            <Spin size="large" />
          </div>
        ) : agentDetailsData && Object.keys(agentDetailsData).length > 0 ? (
          <div className={styles.formContainer}>
            <Form form={form2} layout="vertical" style={{ padding: 10 }} scrollToFirstError>
              <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                <Col span={screens.xs ? 24 : 11}>
                  <Form.Item name="fullName" label="Broker's Name" rules={[{ required: true, message: "Please enter broker's name!" }]}>
                    <Input type="text" allowClear placeholder="Enter broker's name" />
                  </Form.Item>
                </Col>
                <Col span={screens.xs ? 24 : 11}>
                  <Form.Item
                    name="email"
                    label="Email Address"
                    rules={[
                      {
                        type: "email",
                        message: "Not a valid Email!",
                      },
                      {
                        required: true,
                        message: "Please Enter Email!",
                      },
                      { whitespace: true },
                    ]}
                  >
                    <Input type="text" allowClear placeholder="Enter Email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                <Col span={screens.xs ? 24 : 11}>
                  <Form.Item name="firmName" label="Brokerage Firm’s Name:">
                    <Input type="text" allowClear placeholder="Enter Firm’s Name" />
                  </Form.Item>
                </Col>
                <Col span={screens.xs ? 24 : 11}>
                  <Form.Item name="firmLicense" label="Brokerage Firm’s License #">
                    <Input type="text" allowClear placeholder="Enter Firm’s License" />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                <Col span={screens.xs ? 24 : 11}>
                  <Form.Item name="license" label="Broker's License #">
                    <Input type="text" allowClear placeholder="Enter License" />
                  </Form.Item>
                </Col>
                <Col span={screens.xs ? 24 : 11}>
                  <Form.Item name="address" label="Address">
                    <TextArea type="text" placeholder="Enter Address" allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                <Col span={screens.xs ? 24 : 11}>
                  <Form.Item name="phoneNumber" label="Phone No">
                    <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Enter Mobile Number" className="phone-input" />
                  </Form.Item>
                </Col>
                <Col span={screens.xs ? 24 : 11}>
                  <Form.Item name="faxNumber" label="Fax No.">
                    <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Enter Fax Number" className="phone-input" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default EditAgentsModal;
