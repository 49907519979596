   
    export const TableFourinputBox = [
     "PurchasePrice",
     "EarnestMoney",
     "NewLoan",
     "AssumptionBalance",
     "PrivateFinancing",
     "SellerFinancing",
     "CashAtClosing",
     "TotalLeftColumn",
     "TotalRightColumn",
    ]
    export const OtherTemplateDisabledFields = [
      "OriginalContractDate",
      "TaxLegalDescription",
      "DisableAutoPriceCaluations",
      "AutoFillDates",
       "ClosingDate"
    ]
  