import { Button, Input, Space, Table, Select, Menu, Dropdown, List } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logsDataHandle } from '../state/actions';
import { Loading } from '../../Common';
import { FilterFilled, SearchOutlined, DownOutlined } from "@ant-design/icons"
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import "../styles/logs.css";

function SubscriptionLogs() {

  const location = useLocation();
  const dispatch = useDispatch();
  const searchRef = useRef(false);
  const searchInput = useRef();
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [disableBtn, setdisableBtn] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({ logType: 'SUBSCRIPTION_LOGS' });
  const { getLogsData, getLogsLoading, totalRecords } = useSelector((state) => state.getLogsData);

  useEffect(() => {
    let queryData = {
      ...query,
      page: 1,
    }
 
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
  }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {  
    confirm();
    searchRef.current = true;
    setsearchText(selectedKeys[0]);
    let queryData = {
      ...query,
      logType: 'SUBSCRIPTION_LOGS',
      ...(
        dataIndex === 'purchasedForData' ? { purchasedFor: selectedKeys[0] } :
          dataIndex === 'purchasedByData' ? { purchasedBy: selectedKeys[0] } :
            dataIndex === 'subscription_Id' ? { subscription_Id: selectedKeys[0] } :
              dataIndex === 'customerId' ? { customerId: selectedKeys[0] } :
                dataIndex === 'customer_email' ? { customer_email: selectedKeys[0] } :
                  {}
      )
    };

    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    setsearchedColumn(dataIndex);
    searchRef.current = false;
  };

  const handleSort = (dataIndex) => {
    searchRef.current = true;
    let queryData = {
      ...query,
      ...(
        dataIndex === 'createdAt' ? { sortByCreatedAt: searchText } :
          dataIndex === 'updatedAt' ? { sortByUpdatedAt: searchText } :
            dataIndex === 'platformUsed' ? { platformUsed: searchText } :
              {}),
    };
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    searchRef.current = false;
  };

  const handleResetFilter = () => {
    searchRef.current = true;
    let queryData = {
      logType: 'SUBSCRIPTION_LOGS',
      page: currentPage,
    };
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    setsearchText("");
    searchRef.current = false;
  }

  const handleReset = (clearFilters, setSelectedKeys, dataIndex) => {
    searchRef.current = true;
   
    let queryData = { ...query };
    if (dataIndex === 'subscription_Id') {
      delete queryData.subscription_Id;
    } else if (dataIndex === 'customerId') {
      delete queryData.customerId;
    } else if (dataIndex === 'customer_email') {
      delete queryData.customer_email;
    } else if (dataIndex === 'createdAt') {
      delete queryData.sortByCreatedAt;
    } else if (dataIndex === 'updatedAt') {
      delete queryData.sortByUpdatedAt;
    } else if (dataIndex === 'platformUsed') {
      delete queryData.platformUsed;
    } else if (dataIndex === 'onTimePayment') {
      delete queryData.onTimePayment;
    } else if (dataIndex === 'plan') {
      delete queryData.plan;
    } else if (dataIndex === 'subscriptionStatus') {
      delete queryData.subscriptionStatus;
    } else if (dataIndex === 'commonStatus') {
      delete queryData.commonStatus;
    } else if (dataIndex === 'purchasedForData') {
      delete queryData.purchasedFor;
    } else if (dataIndex === 'purchasedByData') {
      delete queryData.purchasedBy;
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    clearFilters();
    setSelectedKeys("");
    setsearchText("");
    searchRef.current = false;
  };

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={
            dataIndex === 'subscription_Id' ? 'Search subscription_Id' :
              dataIndex === 'customerId' ? 'Search Customer Id' :
                dataIndex === 'customer_email' ? 'Search by Customer Email' :
                  `Search by name or email`
          }
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false); }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false); }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys, dataIndex); setdisableBtn(true); }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  let getSort = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder={dataIndex === 'platformUsed' ? 'Select platform' : "Select Order"}
            onChange={(role, event) => {
              setsearchText(role)
            }}
            options={
              dataIndex === 'createdAt' ?
                [
                  {
                    value: "ASC",
                    label: "ASCENDING",
                  },
                  {
                    value: "DESC",
                    label: "DESCENDING",
                  },
                ] :
              dataIndex === 'updatedAt' ?
                [
                  {
                    value: "ASC",
                    label: "ASCENDING",
                  },
                  {
                    value: "DESC",
                    label: "DESCENDING",
                  },
                ] :
                dataIndex === 'platformUsed' ?
                  [
                    {
                      value: "WEB",
                      label: "WEB",
                    },
                    {
                      value: "MOBILE",
                      label: "MOBILE",
                    },
                  ] : []
            }
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSort(dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys, dataIndex); setdisableBtn(true); }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleFilter = (dataIndex) => {
    searchRef.current = true;
    let queryData = {
      ...query,
      [dataIndex]: searchText,
    };
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    searchRef.current = false;
  }

  let getFiltered = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select option"
            onChange={(source, event) => {
              setsearchText(source)
            }}
            options={
              dataIndex === 'commonStatus' ?
                [
                  {
                    value: "ACTIVE",
                    label: "ACTIVE",
                  },
                  {
                    value: "CANCELLED",
                    label: "CANCELLED",
                  },
                  {
                    value: "PAYMENT_FAILED",
                    label: "PAYMENT FAILED",
                  },
                  {
                    value: "REMOVED",
                    label: "REMOVED",
                  },
                  {
                    value: "PAID",
                    label: "PAID",
                  },
                ] :
                dataIndex === 'subscriptionStatus' ?
                  [
                    {
                      value: "active",
                      label: "ACTIVE",
                    },
                    {
                      value: "canceled",
                      label: "CANCELLED",
                    },
                  ] :
                  dataIndex === 'plan' ?
                    [
                      {
                        value: "CLASSIC",
                        label: "CLASSIC",
                      },
                      {
                        value: "RESURE_TRIAL",
                        label: "RESURE TRIAL",
                      },
                      {
                        value: "RESURE_PLAN",
                        label: "RESURE PLAN",
                      },
                      {
                        value: "CANCELLATION_FEE",
                        label: "CANCELLATION FEE",
                      },

                    ] :
                    dataIndex === 'onTimePayment' ?
                      [
                        {
                          value: true,
                          label: 'True',
                        },
                        {
                          value: false,
                          label: 'False',
                        },
                      ] :
                      []
            }
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => handleFilter(dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys, dataIndex); setdisableBtn(true); }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'Purchased For',
      dataIndex: "purchasedForData",
      key: 'purchasedForData',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchProps('purchasedForData'),
      render: (text, record) => (
        <div>
          <div>{record?.purchasedForData?.fullName}</div>
          <div style={{ color: "grey" }}>{record?.purchasedForData?.email}</div>
        </div>
      ),
    },
    {
      title: 'Purchased By',
      dataIndex: 'purchasedByData',
      key: 'purchasedByData',
      align: 'center',
      ...getColumnSearchProps('purchasedByData'),
      render: (text, record) => (
        <div>
          <div>{record?.purchasedByData?.fullName}</div>
          <div style={{ color: "grey" }}>{record?.purchasedByData?.email}</div>
        </div>
      ),
    },
    {
      title: 'Common Status',
      dataIndex: 'commonStatus',
      key: 'commonStatus',
      align: 'center',
      ...getFiltered('commonStatus'),
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: 'Subscription Status',
      dataIndex: 'subscriptionStatus',
      key: 'subscriptionStatus',
      align: 'center',
      ...getFiltered('subscriptionStatus'),
      render: (text) => (
        <div>
          <div>{text ? text.toUpperCase() : ""}</div>
        </div>
      ),
    },
    {
      title: 'Subscription Id',
      dataIndex: 'subscription_Id',
      key: 'subscription_Id',
      align: 'center',
      ...getColumnSearchProps('subscription_Id'),
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: 'Customer Id',
      dataIndex: 'customerId',
      key: 'customerId',
      align: 'center',
      ...getColumnSearchProps('customerId'),
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: 'Customer Email',
      dataIndex: 'customer_email',
      key: 'customer_email',
      align: 'center',
      ...getColumnSearchProps('customer_email'),
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
      align: 'center',
      ...getFiltered('plan'),
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: 'Plan Details',
      dataIndex: 'planDetails',
      key: 'planDetails',
      align: 'center',
      render: (text, record) => (
        <Dropdown overlay={(
          <Menu>
            <Menu.Item key="1">
              <table id='planDetails'>
                <tbody>
                  <tr>
                    <td className='label'>Currency:</td>
                    <td className='value'>{text?.currency}</td>
                  </tr>
                  <tr>
                    <td className='label'>Interval:</td>
                    <td className='value'>{text?.interval}</td>
                  </tr>
                  <tr>
                    <td className='label'>Interval Count:</td>
                    <td className='value'>{text?.interval_count}</td>
                  </tr>
                  <tr>
                    <td className='label'>Unit Amount:</td>
                    <td className='value'>{text?.unit_amount}</td>
                  </tr>
                  <tr>
                    <td className='label'>Plan Id:</td>
                    <td className='value'>{text?.planId}</td>
                  </tr>
                  <tr>
                    <td className='label'>Product Id:</td>
                    <td className='value'>{text?.productId}</td>
                  </tr>
                </tbody>
                {/* <tbody>
                  {
                    text?.currency ?
                      <tr>
                        <td className='label'>Currency:</td>
                        <td className='value'>{text?.currency}</td>
                      </tr> : ''
                  }
                  {
                    text?.interval ?
                      <tr>
                        <td className='label'>Interval:</td>
                        <td className='value'>{text?.interval}</td>
                      </tr> : ''
                  }
                  {text?.interval_count ?
                    <tr>
                      <td className='label'>Interval Count:</td>
                      <td className='value'>{text?.interval_count}</td>
                    </tr> : ''
                  }
                  {text?.unit_amount ?
                    <tr>
                      <td className='label'>Unit Amount:</td>
                      <td className='value'>{text?.unit_amount}</td>
                    </tr> : ''
                  }
                  {text?.planId ?
                    <tr>
                      <td className='label'>Plan Id:</td>
                      <td className='value'>{text?.planId}</td>
                    </tr> : ''
                  }
                  {text?.productId ?
                    <tr>
                      <td className='label'>Product Id:</td>
                      <td className='value'>{text?.productId}</td>
                    </tr> : ''
                  }
                </tbody> */}
              </table>
            </Menu.Item>
          </Menu>
        )} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            Show Details <DownOutlined />
          </a>
        </Dropdown>
      ),
    },
    {
      title: 'Payment Details',
      dataIndex: 'paymentDetails',
      key: 'paymentDetails',
      align: 'center',
      render: (text, record) => (
        <Dropdown overlay={(
          <Menu style={{ maxWidth: '600px' }}>
            <List>
              <List.Item key="1" style={{ padding: '10px' }}>
                <table id='paymentDetails'>
                  <tbody>
                    <tr>
                      <td className='label'>Amount Due:</td>
                      <td className='value'>{text?.amount_due}</td>
                    </tr>
                    <tr>
                      <td className='label'>Amount Paid:</td>
                      <td className='value'>{text?.amount_paid}</td>
                    </tr>
                    <tr>
                      <td className='label'>Amount Remaining:</td>
                      <td className='value'>{text?.amount_remaining}</td>
                    </tr>
                    <tr>
                      <td className='label'>Currency:</td>
                      <td className='value'>{text?.currency}</td>
                    </tr>
                    <tr>
                      <td className='label'>Invoice Number:</td>
                      <td className='value'>{text?.invoiceNumber}</td>
                    </tr>
                    <tr>
                      <td className='label'>Payment Date:</td>
                      <td className='value'>{moment.unix(text?.paymentDate).format("MMMM D, YYYY hh:mm A")}</td>
                    </tr>
                    <tr>
                      <td className='label'>Sub-total:</td>
                      <td className='value'>{text?.subtotal}</td>
                    </tr>
                    <tr>
                      <td className='label'>Sub-total(Excluding Tax):</td>
                      <td className='value'>{text?.subtotal_excluding_tax}</td>
                    </tr>
                    <tr>
                      <td className='label'>Total:</td>
                      <td className='value'>{text?.total}</td>
                    </tr>
                    <tr>
                      <td className='label'>Total(Excluding Tax):</td>
                      <td className='value'>{text?.total_excluding_tax}</td>
                    </tr>
                    <tr>
                      <td className='label'>Unit Amount:</td>
                      <td className='value'>{text?.unit_amount}</td>
                    </tr>
                    <tr>
                      <td className='label'>Discount name:</td>
                      <td className='value'>{text?.discount?.name}</td>
                    </tr>
                    <tr>
                      <td className='label'>Discount Percent off:</td>
                      <td className='value'>{text?.discount?.percent_off}</td>
                    </tr>
                    <tr>
                      <td className='label'>Payment Intent Id:</td>
                      <td className='value'>{text?.payment_intent_id}</td>
                    </tr>
                    <tr>
                      <td className='label'>Invoice Id:</td>
                      <td className='value'>{text?.invoiceId}</td>
                    </tr>
                    <tr>
                      <td className='label'>Hosted Invoice Url:</td>
                      <td className='value' copyable>{text?.hosted_invoice_url}</td>
                    </tr>
                  </tbody>
                </table>
              </List.Item>
            </List>
          </Menu>
        )} placement="bottomCenter" trigger={['click']}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            Show Details <DownOutlined />
          </a>
        </Dropdown>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      render: (text) => (
        <div>
          {text ? <div>{moment.unix(text).format("MMMM D, YYYY hh:mm A")}</div> : ""}
        </div>
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      render: (text) => (
        <div>
          {text ? <div>{moment.unix(text).format("MMMM D, YYYY hh:mm A")}</div> : ""}
        </div>
      ),
    },
    // {
    //   title: location.pathname === '/propertyCreatedLogs' ? 'Property created for Seller agent' : 'Created Through',
    //   dataIndex: location.pathname === '/propertyCreatedLogs' ? 'manualPropertyCreatedForSellerAgentData' : 'mlsPropertyCreatedLog',
    //   key: 'createdThrough',
    //   align: 'center',
    //   ...(location.pathname === '/propertyCreatedLogs' ? searchSellerAgent() : filterByCreatedThrough('createdThrough')),
    //   render: (text) => (
    //     <div>
    //       {location.pathname === '/propertyCreatedLogs' ?
    //         <>
    //           <div>{text?.fullName}</div>
    //           <div>{text?.email}</div>
    //         </>
    //         :
    //         <>
    //           {
    //             text?.createdThrough === 'RESIDENTIAL_OFFER' ? <div style={{ color: "blue" }}>RESIDENTIAL OFFER</div> :
    //               text?.createdThrough === 'LINK_MLS' ? <div style={{ color: "orange" }}>LINK MLS</div> :
    //                 text?.createdThrough === 'MLS_AGENT_ID' ? <div style={{ color: "red" }}>MLS AGENT ID</div> : ""
    //           }
    //         </>
    //       }
    //     </div>
    //   )
    // },

    {
      title: 'One Time Payment',
      dataIndex: 'onTimePayment',
      key: 'onTimePayment',
      align: 'center',
      ...getFiltered('onTimePayment'),
      render: (text) => (
        <div>
          <div>{text ? 'True' : 'False'}</div>
        </div>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      ...getSort('createdAt'),
      render: (text) => (
        <div>
          <div>{moment(text).format("MMMM D, YYYY hh:mm A")}</div>
        </div>
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      ...getSort('updatedAt'),
      render: (text) => (
        <div>
          <div>{moment(text).format("MMMM D, YYYY hh:mm A")}</div>
        </div>
      ),
    },
    {
      title: 'Platform Used',
      dataIndex: 'platformUsed',
      key: 'PlatformUsed',
      align: 'center',
      // ...getSort('platformUsed'),
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      )
    },
  ]



  return (
    <div id='logs'>{
      getLogsLoading ?
        <div style={{ height: "100vh", display: "flex", justifyContent: "center" }}>
          <Loading />
        </div> :
        <>
          <div className='headerDiv'>
            <div className='resetBtn'>
              <Button onClick={handleResetFilter} disabled={Object.keys(query).filter(key => key !== 'logType' && key !== 'page' && key !== 'limit').length === 0}>Reset Filter</Button>
            </div>
            <div className='titleDiv'>
              <h1>SUBSCRIPTION LOGS</h1>
            </div>
            <div className='blankDiv'></div>
          </div>
          <Table
            dataSource={getLogsData}
            columns={columns}
            loading={getLogsLoading}
            bordered
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: false,
              pageSize: 5,
              current: currentPage,
              total: totalRecords,
              onChange: (page) => {
                if (!searchRef.current) {
           
                  let queryData = {
                    ...query,
                    page
                  }
                  dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
                  // setQuery(queryData);
                  setCurrentPage(page);
                }
              },
            }}
            scroll={{
              y: 460,
              x: 2500,
            }}
          />
        </>}
    </div>
  )
}

export default SubscriptionLogs
